import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a0e7abe8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tw-ml-auto tw-mr-auto tw-text-[2rem] tw-text-secondary tw-font-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("header", {
    class: _normalizeClass([[{login:!_ctx.isLogin}], "tw-inline-flex tw-items-center tw-w-full tw-h-[4rem] tw-min-h-[4rem] tw-bg-primary tw-relative"])
  }, [
    _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.url), 1),
    (_ctx.isLogin)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.logout && _ctx.logout(...args))),
          class: "tw-absolute tw-right-[1rem] tw-text-tertiary tw-text-[1.2rem] tw-font-bold tw-cursor-pointer"
        }, " Abmelden "))
      : _createCommentVNode("", true)
  ], 2))
}