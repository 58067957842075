import html2canvas from 'html2canvas';
import MediaService from '@/services/media.service';
import { useStore } from 'vuex';
import { key } from '@/stores';

function useThumbnailCreatePresentation() {
  const store = useStore(key);

  async function updateThumbLocally(newThumbId: number, viewId: number, base64: string) {
    const data = {
      newThumbId,
      viewId,
      base64,
    };
    try {
      await store.dispatch({
        type: 'presentation/updateThumbnail',
        data,
      });
    } catch (e: any) {
      throw new Error(e.message);
    }
  }

  async function updateThumbnail() {
    try {
      const presentationMain = document.querySelector('.presentation-main') as HTMLElement | null;
      if (presentationMain) {
        const canvas = await html2canvas(presentationMain, {
          onclone: (_, element) => {
            // eslint-disable-next-line no-param-reassign
            element.style.transform = 'scale(0.2)';
          },
        });
        // convert to img bas64
        const data = canvas.toDataURL('image/jpeg', 0.4);
        const viewId: number = store.getters['presentation/activeView'];
        const thumbnailId = store.getters['presentation/presentationThumbnails'][viewId].id;
        if (!thumbnailId) {
          const response: any = await new MediaService().post({
            model_id: store.getters['presentation/activeView'],
            model_type: 'printData',
            is_thumb: true,
            media: data,
          });
          // update locally
          await updateThumbLocally(response.data.data.id as number, viewId, data);
        } else {
          const response: any = await new MediaService().id(thumbnailId)
            .post({
              media: data,
              is_thumb: true,
            });
          // update locally
          await updateThumbLocally(response.data.data.id as number, viewId, data);
        }
      } else {
        throw new Error('presentation-main not found');
      }
    } catch (e: any) {
      throw new Error(e.message);
    }
  }

  return { updateThumbnail };
}

export default useThumbnailCreatePresentation;
