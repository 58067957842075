export enum AuthGetters {
  TOKEN = 'TOKEN',
  ERROR_MSG = 'ERROR_MSG',
  PERMISSIONS = 'PERMISSIONS',
}

export enum AuthMutations {
  LOGIN= 'LOGIN',
  LOGOUT= 'LOGOUT',
  SET_ERROR_MSG = 'SET_ERROR_MSG',
  PERMISSIONS = 'PERMISSIONS',
}

export enum AuthActions {
  TRY_LOGIN= 'TRY_LOGIN',
  LOGIN= 'LOGIN',
  SET_ERROR_MSG = 'SET_ERROR_MSG',
  LOGOUT = 'LOGOUT',
  PERMISSIONS = 'PERMISSIONS',
}
