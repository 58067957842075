import { Module } from 'vuex';
import { AuthStateTypes } from '@/stores/modules/auth/auth.interface';
import { IRootState } from '@/stores/interfaces';
import { authMutations } from '@/stores/modules/auth/auth.mutations';
import { authActions } from '@/stores/modules/auth/auth.actions';
import { authGetters } from '@/stores/modules/auth/auth.getters';
import { authState } from '@/stores/modules/auth/auth.state';

const authStore: Module<AuthStateTypes, IRootState> = {
  state: authState,
  getters: authGetters,
  mutations: authMutations,
  actions: authActions,
};
export default authStore;
