import { createStore, Store } from 'vuex';
import { InjectionKey } from 'vue';
import presentation from '@/stores/modules/presentation.store';
import { IRootState } from '@/stores/interfaces';
import orderStore from '@/stores/modules/order/order.store';
import authStore from '@/stores/modules/auth/auth.store';

export const key: InjectionKey<Store<IRootState>> = Symbol('store symbol');

export const store = createStore<IRootState>({
  modules: {
    orders: orderStore,
    presentation,
    auth: authStore,
  },
  state() {
    return {
      isFetching: false,
      errorStack: [],
      errorId: 0,
    };
  },
  actions: {
    setIsFetching({ commit }: { commit: any }, payload: any) {
      commit({
        type: 'setIsFetching',
        data: payload.data,
      });
    },
    pushToErrorStack({ commit }: { commit: any }, payload: any) {
      commit({ type: 'pushToErrorStack', error: payload.error });
    },
    removeFromErrorStack({ commit }: { commit: any }, payload: any) {
      commit({ type: 'removeFromErrorStack', id: payload.id });
    },
  },
  mutations: {
    setIsFetching(state: any, payload: any) {
      state.isFetching = payload.data;
    },
    pushToErrorStack(state: any, payload: any) {
      state.errorId += 1;
      const errObj = {
        id: state.errorId,
        msg: payload.error.request ? payload.error.request.responseURL : payload.error.msg,
        status: payload.error.response ? payload.error.response.status : payload.error.status,
      };
      state.errorStack.push(errObj);
    },
    removeFromErrorStack(state: any, payload: any) {
      const toFilter = [...state.errorStack];
      const errId = payload.id;
      state.errorStack = toFilter.filter((err) => errId !== err.id);
    },
  },
  getters: {
    isFetching(state: any) {
      return state.isFetching;
    },
    errorStack(state: any) {
      return state.errorStack;
    },
  },
});

export function useStore() {
  return store as Store<any>;
}
