import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "tw-flex-grow tw-w-full tw-inline-flex tw-overflow-hidden tw-relative" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_spinner = _resolveComponent("spinner")!
  const _component_page_header = _resolveComponent("page-header")!
  const _component_page_navigation = _resolveComponent("page-navigation")!
  const _component_page_menu = _resolveComponent("page-menu")!
  const _component_presentation_back_drop_busy = _resolveComponent("presentation-back-drop-busy")!
  const _component_page_side_menu = _resolveComponent("page-side-menu")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_page_footer = _resolveComponent("page-footer")!
  const _component_snackbars = _resolveComponent("snackbars")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.fetching)
      ? (_openBlock(), _createBlock(_component_spinner, { key: 0 }))
      : _createCommentVNode("", true),
    _createVNode(_component_page_header),
    _createVNode(_component_page_navigation),
    _createVNode(_component_page_menu),
    _createElementVNode("div", _hoisted_1, [
      false
        ? (_openBlock(), _createBlock(_component_presentation_back_drop_busy, { key: 0 }))
        : _createCommentVNode("", true),
      _createVNode(_component_page_side_menu),
      _createVNode(_component_router_view)
    ]),
    _createVNode(_component_page_footer),
    _createVNode(_component_snackbars)
  ], 64))
}