import { TextHandler } from '@/models/presentation.model';

class TextContainer implements TextHandler {
  textElem: HTMLDivElement;

  range: Range;

  rightClickTextFieldId = (event: Event) => {
    console.log(event, this);
  };

  boldHandlerId = (event: Event) => {
    console.log(event, this);
  };

  constructor(textContainer: HTMLDivElement) {
    this.textElem = textContainer;
    this.range = new Range();
  }
}

export default TextContainer;
