

import { computed, defineComponent } from 'vue';
import { useStore } from 'vuex';
import { key } from '@/stores';
import GenericButton from '@/components/generic/GenericButton.vue';
import useUpdateViewPresentation from '@/customHooks/use-update-view-presentation';
import useDragDropViewPresentation from '@/customHooks/use-drag-drop-view-presentation';

export default defineComponent({
  components: { GenericButton },
  setup() {
    const { updateView } = useUpdateViewPresentation();
    const {
      draggedId,
      dragStart,
      dragEnter,
      dragEnd,
    } = useDragDropViewPresentation();
    const store = useStore(key);
    const views = computed(() => store.getters['presentation/viewIds']);
    const activeView = computed(() => store.getters['presentation/activeView']);
    const noData = computed(() => store.getters['presentation/noData']);
    const thumbnails = computed(() => store.getters['presentation/presentationThumbnails']);
    const deletePermission = computed(() => store.getters['presentation/deletePermission']);
    // const draggedId = ref<number>(-1);

    async function selectView(id: number) {
      const currentId = store.getters['presentation/activeView'];
      if (currentId === id) return; // clicking on same just ignores update
      try {
        await updateView();
        await store.dispatch({
          type: 'presentation/getView',
          updateId: true,
          id,
        });
      } catch (e: any) {
        console.log(e.message);
      } finally {
        await store.dispatch({
          type: 'setIsFetching',
          data: false,
        });
      }
    }

    async function addView() {
      try {
        await store.dispatch({
          type: 'setIsFetching',
          data: true,
        });
        await store.dispatch('presentation/getLayouts'); // fetch layouts
        await store.dispatch({
          type: 'presentation/modalShow',
          data: 'add',
        });
      } catch (e: any) {
        console.log(e.message);
      } finally {
        await store.dispatch({
          type: 'setIsFetching',
          data: false,
        });
      }
    }

    function deleteView() {
      store.dispatch('presentation/deleteView');
    }

    async function saveTemplate() {
      try {
        await updateView();
        await store.dispatch({
          type: 'presentation/modalShow',
          data: 'saveTemplate',
        });
      } catch (e: any) {
        console.log(e.message);
      } finally {
        await store.dispatch({
          type: 'setIsFetching',
          data: false,
        });
      }
    }

    async function duplicateView() {
      try {
        await updateView();
        // call add view with same payload as active view
        const viewId = store.getters['presentation/activeView'];

        // dispatch add view
        await store.dispatch({
          type: 'presentation/addView',
          template_ids: [viewId],
          view: '',
        });
      } catch (e: any) {
        console.log(e.message);
      } finally {
        await store.dispatch({
          type: 'setIsFetching',
          data: false,
        });
      }
    }

    return {
      views,
      activeView,
      selectView,
      addView,
      deleteView,
      saveTemplate,
      noData,
      duplicateView,
      dragEnter,
      dragStart,
      dragEnd,
      draggedId,
      thumbnails,
      deletePermission,
    };
  },
});
