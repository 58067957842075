
import {
  defineComponent, onMounted,
  onUnmounted, ref,
} from 'vue';

export default defineComponent(
  {
    setup() {
      const fetching = ref<boolean>(false);
      let id = -1;

      onMounted(() => {
        id = setTimeout(() => {
          fetching.value = true;
        }, 400);
      });

      onUnmounted(() => clearTimeout(id));

      return {
        fetching,
      };
    },
  },
);
