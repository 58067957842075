import { useRoute } from 'vue-router';
import { ref, watch } from 'vue';

// hook which on route change checks if the element should appear or not
// refactor - try to update hook only when certain routes changes
function useCurrentUrl(onUrlHide: string[]) {
  const route = useRoute();
  const isMatch = onUrlHide.find((value) => value === route.fullPath);
  const hide = ref<boolean>(Boolean(isMatch));
  const currentPath = ref<string>(route.path.slice(1));

  watch(route, () => {
    const m = Boolean(onUrlHide.find((value) => value === route.fullPath));
    hide.value = m;
    currentPath.value = route.path.slice(1);
  });

  return {
    hide,
    currentPath,
  };
}

export default useCurrentUrl;
