import { YtContainer } from '@/models/presentation.model';

class YouTubeVideoContainer implements YtContainer {
  videoContainer: HTMLDivElement;

  videoFallback: HTMLDivElement;

  videoElem: HTMLIFrameElement;

  btn: HTMLButtonElement;

  constructor(ytContainer: HTMLDivElement, fallbackClass: string) {
    this.videoContainer = ytContainer;
    // eslint-disable-next-line prefer-destructuring
    this.videoFallback = this.videoContainer.querySelector(fallbackClass) as HTMLDivElement;
    // eslint-disable-next-line prefer-destructuring
    this.videoElem = this.videoContainer.getElementsByTagName('iframe')[0];
    // eslint-disable-next-line prefer-destructuring
    this.btn = this.videoContainer.getElementsByTagName('button')[0];
  }

  init() {
    if (this.videoElem.src.trim().length === 0) {
      this.videoFallback.style.display = 'block';
      this.btn.textContent = 'Video wählen';
    } else {
      this.videoElem.style.display = 'block';
      this.btn.textContent = 'Video ändern';
    }
    return this;
  }

  addOnEnterLeaveListeners():YtContainer {
    this.videoContainer.addEventListener('mouseenter', this.onEnterShowButton.bind(this));
    this.videoContainer.addEventListener('mouseleave', this.onLeaveHideButton.bind(this));
    return this;
  }

  addOnClickButtonListener(handler:(show:boolean)=>void):YtContainer {
    this.btn.addEventListener('click', handler.bind(null, true));
    return this;
  }

  onUnmount() {
    this.videoContainer.removeEventListener('mouseenter', this.onEnterShowButton.bind(this));
    this.videoContainer.removeEventListener('mouseleave', this.onLeaveHideButton.bind(this));
    return this;
  }

  removeOnClickButtonListener(handler:(show:boolean)=>void):YtContainer {
    this.btn.removeEventListener('click', handler.bind(null, true));
    return this;
  }

  onEnterShowButton(_:MouseEvent) {
    if (this.btn.style.display === 'none') {
      this.btn.style.display = 'block';
    }
  }

  onLeaveHideButton(_:MouseEvent) {
    if (this.btn.style.display === 'block') {
      this.btn.style.display = 'none';
    }
  }

  addYtLink(link:string) {
    this.videoFallback.style.display = 'none';
    this.videoElem.src = link;
    this.videoElem.style.display = 'block';
    this.btn.textContent = 'Video ändern';
  }

  editYtLink(link:string) {
    this.videoElem.src = link;
  }
}

export default YouTubeVideoContainer;
