

import {
  computed,
  defineComponent,
  reactive,
  ref,
  watch,
} from 'vue';
import GenericButton from '@/components/generic/GenericButton.vue';
import YouTubeVideoContainer from '@/models/presentation.youtube.model';
import validateYtLink from '@/utils/validators/yt.link.validation';

export default defineComponent({
  props: {
    close: {
      type: Function,
      required: true,
    },
    elem: {
      type: YouTubeVideoContainer,
      required: true,
    },
  },
  components: {
    GenericButton,
  },
  setup(props) {
    const input = reactive({
      videoId: props.elem.videoElem.src.length > 0 ? props.elem.videoElem.src.split('/')[4] : '',
      isError: false,
      isFocus: false,
    });

    const showVideoPreview = ref<boolean>(props.elem.videoElem.src.length > 0);

    const ytLink = computed(() => `https://www.youtube.com/embed/${input.videoId}`);

    function onFocus() {
      input.isFocus = true;
    }

    function onBlur() {
      input.isFocus = false;
    }

    function onSubmit(e:Event) {
      e.preventDefault();
      if (input.isError) {
        return;
      }
      if (validateYtLink(input.videoId)) {
        input.isError = true;
        return;
      }
      if (props.elem.videoElem.src.length === 0) {
        props.elem.addYtLink(ytLink.value);
      } else if (props.elem.videoElem.src.length > 0) {
        props.elem.editYtLink(ytLink.value);
      }
      props.close();
    }

    watch(() => input.isFocus, (newVal) => {
      if (!newVal) {
        const isErr = validateYtLink(input.videoId);
        input.isError = isErr;
        showVideoPreview.value = !isErr;
      }
    });

    return {
      input,
      showVideoPreview,
      yt: ytLink,
      onFocus,
      onBlur,
      onSubmit,
    };
  },
});

