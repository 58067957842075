import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "tw-flex-grow tw-pr-[1rem] tw-text-white tw-overflow-hidden" }
const _hoisted_2 = { class: "tw-text-left tw-text-tertiary tw-text-[1.2rem] tw-font-bold" }
const _hoisted_3 = { class: "tw-text-left tw-w-[20rem] tw-text-[1.4rem]" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_generic_icon = _resolveComponent("generic-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["tw-absolute tw-bottom-[3rem] tw-right-[1rem] tw-flex-col", [{hide:_ctx.errorStack.length === 0}]])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.errorStack, (item) => {
      return (_openBlock(), _createElementBlock("div", {
        key: item.id,
        class: "tw-mb-[1rem] tw-w-[30rem] tw-min-h-[8rem] tw-p-[1rem] tw-flex tw-items-center tw-justify-start tw-bg-secondary tw-shadow tw-rounded"
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("p", _hoisted_2, "Fehler: " + _toDisplayString(item.status), 1),
          _createElementVNode("p", _hoisted_3, "URL: " + _toDisplayString(item.msg), 1)
        ]),
        _createVNode(_component_generic_icon, {
          onClick: _ctx.clearSnackbar.bind(null,item.id),
          src: require('../assets/close.svg'),
          bc: 'tertiary'
        }, null, 8, ["onClick", "src"])
      ]))
    }), 128))
  ], 2))
}