export enum Routes {
  LOGIN = '/login',
  ORDER = '/order',
  PRESENTATION = '/presentation',
  NOT_FOUND = '/not_found',
}

export enum RoutesDE{
  LOGIN = '/anmelden',
  ORDER = '/auftrag',
  PRESENTATION = '/präsentation',
  NOT_FOUND = '/not_found',
}

export default Routes;
