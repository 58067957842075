import { Module } from 'vuex';
import { OrderStateTypes } from '@/stores/modules/order/order.interface';
import { IRootState } from '@/stores/interfaces';
import { orderMutations } from '@/stores/modules/order/order.mutations';
import { orderActions } from '@/stores/modules/order/order.actions';
import { orderGetters } from '@/stores/modules/order/order.getters';
import { orderState } from '@/stores/modules/order/order.state';

const orderStore: Module<OrderStateTypes, IRootState> = {
  state: orderState,
  getters: orderGetters,
  mutations: orderMutations,
  actions: orderActions,
};
export default orderStore;
