

import { defineComponent } from 'vue';
import GenericButton from '@/components/generic/GenericButton.vue';

export default defineComponent({
  components: { GenericButton },
  props: {
    yes: {
      type: [Function],
      required: true,
    },
    no: {
      type: [Function],
      required: true,
    },
    text: {
      type: [String],
      default: 'Props',
    },
  },
});
