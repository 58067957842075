

import {
  computed,
  defineComponent, onBeforeUnmount, onMounted,
  ref,
} from 'vue';
import { useStore } from 'vuex';
import { key } from '@/stores';
import GenericButton from '@/components/generic/GenericButton.vue';
import PrintService from '@/services/print.service';
import useUpdateViewPresentation from '@/customHooks/use-update-view-presentation';
import router from '@/router';
import RoutesEnum from '@/routes.enum';

export default defineComponent({
  components: { GenericButton },
  setup() {
    const { updateView } = useUpdateViewPresentation();
    const store = useStore(key);
    const views = computed(() => store.getters['presentation/layouts']);
    const deletePermission = computed(() => store.getters['presentation/deletePermission']);
    const clickedTemplateId = computed(() => store.getters['presentation/clickedTemplateId']);
    const clickedView = ref<string>('');
    const btnMsg = ref<string>('Abschicken');
    const templateViews = ref<Array<any>>([]);
    const isFocusSearch = ref<boolean>(false);
    const inputSearch = ref<string>('');
    const rename = ref<string>('');
    const isRenameFocus = ref<boolean>(false);

    async function onFocus() {
      isFocusSearch.value = true;
      btnMsg.value = 'Abschicken';
      clickedView.value = '';
      await store.dispatch({
        type: 'presentation/setClickedTemplateId',
        id: null,
      });
    }

    function onBlur() {
      isFocusSearch.value = false;
    }

    function onFocusRename() {
      isRenameFocus.value = true;
    }

    function onBlurRename() {
      isRenameFocus.value = false;
    }

    let debounceId = -1;

    // search
    function search() {
      clearTimeout(debounceId);
      debounceId = setTimeout(async () => {
        try {
          clickedView.value = '';
          await store.dispatch({
            type: 'presentation/setClickedTemplateId',
            id: null,
          });
          if (inputSearch.value.trim() !== '') {
            console.log(typeof inputSearch.value);
            const response: any = await new PrintService().filter()
              .search(inputSearch.value)
              .get();
            console.log(response);
            templateViews.value = response.data.data.data;
          } else {
            templateViews.value = [];
          }
        } catch (e: any) {
          console.log(e.message);
        }
      }, 500);
    }

    // save view name
    async function saveView(item: any) {
      btnMsg.value = 'Abschicken';
      await store.dispatch({
        type: 'presentation/setClickedTemplateId',
        id: null,
      });
      clickedView.value = item.view;
      // get templates
      const { templates } = item;
      // get template obj
      templateViews.value = templates.length !== 0 ? templates : [];
    }

    // save optionally id of a custom created template (view)
    async function saveTemplate(template: any) {
      clickedView.value = '';
      btnMsg.value = 'Abschicken';
      await store.dispatch({
        type: 'presentation/setClickedTemplateId',
        id: template.id,
      });
      // if only one template selected - fill rename input
      if (store.getters['presentation/clickedTemplateId']) {
        const activeId = store.getters['presentation/clickedTemplateId'].length === 1 ? store.getters['presentation/clickedTemplateId'][0] : -1;
        if (activeId !== -1) {
          const activeTemplate = templateViews.value.find((obj) => obj.id === activeId);
          if (activeTemplate) rename.value = activeTemplate.template_name;
        }
      }
    }

    // deleteTemplate
    function deleteTemplate() {
      store.dispatch({
        type: 'presentation/modalShow',
        data: 'deleteTemplate',
      });
    }

    // add layout to presentation
    async function postReq() {
      if (clickedView.value === '' && !store.getters['presentation/clickedTemplateId']) {
        btnMsg.value = 'bitte Auswählen';
        return;
      } // will return Promise be aware
      try {
        if (store.getters['presentation/noData']) {
          await updateView();
        }
        await store.dispatch({
          type: 'presentation/addView',
          view: clickedView.value,
          template_ids: store.getters['presentation/clickedTemplateId'] ? [...store.getters['presentation/clickedTemplateId']] : null,

        });
        await router.replace(`${RoutesEnum.PRESENTATION}/${store.getters['presentation/activePresentationId']}`);
      } catch (e) {
        console.log('ERROR - ADD VIEW');
      } finally {
        await store.dispatch({
          type: 'presentation/modalShow',
          data: false,
        });
        await store.dispatch({
          type: 'setIsFetching',
          data: false,
        });
      }
    }

    async function renameSavedTemplate() {
      try {
        const name = rename.value;
        const id = store.getters['presentation/clickedTemplateId'] ? store.getters['presentation/clickedTemplateId'][0] : null;
        if (!id || name === '') throw new Error('either id or name is null');
        console.log('rename', name, id);
        await store.dispatch({
          type: 'setIsFetching',
          data: true,
        });
        await new PrintService().put({ id, template_name: name });
        //  update local naming
        if (templateViews.value.length > 0) {
          templateViews.value.forEach((template) => {
            // eslint-disable-next-line no-param-reassign
            if (template.id === id) template.template_name = name;
          });
        }
      } catch (e:any) {
        throw new Error(e.message);
      } finally {
        await store.dispatch({
          type: 'setIsFetching',
          data: false,
        });
      }
    }

    onBeforeUnmount(() => {
      clearTimeout(debounceId);
    });

    onMounted(async () => {
      // clear clicked id on mount
      await store.dispatch({
        type: 'presentation/setClickedTemplateId',
        id: null,
      });
    });

    return {
      views,
      postReq,
      saveView,
      templateViews,
      clickedView,
      saveTemplate,
      deleteTemplate,
      clickedTemplateId,
      btnMsg,
      onFocus,
      onBlur,
      isFocusSearch,
      inputSearch,
      search,
      rename,
      isRenameFocus,
      onFocusRename,
      onBlurRename,
      renameSavedTemplate,
      deletePermission,
    };
  },
});
