
import GenericIcon from '@/components/generic/GenericIcon.vue';
import {
  computed, defineComponent,
  onBeforeUnmount,
  ref,
} from 'vue';
import useCurrentUrl from '@/customHooks/use-current-url';
import { useRoute } from 'vue-router';
import RoutesEnum from '@/routes.enum';
import routesModel from '@/models/routes.model';

export default defineComponent({
  components: { GenericIcon },
  setup() {
    const { hide } = useCurrentUrl([RoutesEnum.LOGIN, RoutesEnum.NOT_FOUND]);
    const links = routesModel.getClickableItems();
    const isEnter = ref<boolean>(false);
    const route = useRoute();
    const currentPath = computed(() => route.fullPath);
    let id = 0;

    function mouseEnterHandler() {
      id = setTimeout(() => {
        isEnter.value = true;
      }, 300);
    }

    // TODO: router don't allow to change on loading spinner
    // add snackbar
    function mouseLeaveHandler() {
      clearTimeout(id);
      isEnter.value = false;
    }

    onBeforeUnmount(() => clearTimeout(id));

    return {
      hide,
      mouseEnterHandler,
      mouseLeaveHandler,
      isEnter,
      links,
      currentPath,
    };
  },
});
