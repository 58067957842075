import { MutationTree } from 'vuex';
import { Order } from '@/models/order.model';
import {
  OrderMutationsTypes,
  OrderStateTypes,
} from '@/stores/modules/order/order.interface';
import { ORDER_STORE } from '@/stores/modules/order/order.constants';

export const orderMutations: MutationTree<OrderStateTypes> & OrderMutationsTypes = {
  [ORDER_STORE.MUTATIONS.SET_ORDERS](
    state: OrderStateTypes,
    payload: Array<Order>,
  ) {
    state.orders = payload;
  },
};

export default orderMutations;
