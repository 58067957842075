import BaseService from '@/services/base.service';

export default class MediaService<T> extends BaseService<T> {
  // Set the resource route of the model
  resource = 'media';

  id(id: number) {
    this.resource += `/${id}`;
    return this;
  }
}
