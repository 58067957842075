import { ref } from 'vue';
import { useStore } from 'vuex';
import { key } from '@/stores';
import useUpdateViewPresentation from '@/customHooks/use-update-view-presentation';

// DRAG AND DROP HOOK

function useDragDropViewPresentation() {
  const store = useStore(key);
  const draggedId = ref<number>(-1);
  const { updateView } = useUpdateViewPresentation();
  let newPos = 0;

  async function dragEnter(viewId: number) {
    if (draggedId.value !== viewId) {
      // re - ordering of id's
      const oldArr = [...store.getters['presentation/viewIds']];
      const indexDropped = oldArr.indexOf(viewId);
      const indexDragged = oldArr.indexOf(draggedId.value);
      // re-order
      oldArr.forEach((_, index: number) => {
        // dropped > dragged - downwards
        if (indexDropped > indexDragged && index >= indexDragged && index < indexDropped) {
          const temp = oldArr[index + 1];
          oldArr[index + 1] = oldArr[index];
          oldArr[index] = temp;
        }
        // dropped < dragged - upwards
        if (indexDropped < indexDragged && index >= indexDropped && index < indexDragged) {
          const offset = indexDropped + indexDragged;
          const temp = oldArr[offset - index - 1];
          oldArr[offset - index - 1] = oldArr[offset - index];
          oldArr[offset - index] = temp;
        }
      });
      newPos = indexDropped + 1;
      try {
        await store.dispatch({
          type: 'presentation/reorderViewIds',
          data: oldArr,
        });
      } catch (e: any) {
        console.log(e.message);
      }
    }
  }

  function dragStart(viewId: number) {
    draggedId.value = viewId;
  }

  async function dragEnd(viewId: number) {
    draggedId.value = -1;
    // create PUT
    if (newPos) {
      // TODO: add update hook and back-end repair the empty body on put
      try {
        await store.dispatch({
          type: 'setIsFetching',
          data: true,
        });
        await updateView();
        await store.dispatch({
          type: 'presentation/sortPresentations',
          id: viewId,
          sortId: newPos,
        });
        // reset
        newPos = 0;
      } catch (e: any) {
        // reset
        newPos = 0;
      } finally {
        await store.dispatch({
          type: 'setIsFetching',
          data: false,
        });
      }
    }
  }

  return {
    draggedId,
    dragEnter,
    dragStart,
    dragEnd,
  };
}

export default useDragDropViewPresentation;
