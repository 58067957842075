
import { useStore } from 'vuex';
import { computed, defineComponent, onMounted } from 'vue';
import { key } from '@/stores';
import { ORDER_STORE } from '@/stores/modules/order/order.constants';
import { onBeforeRouteLeave } from 'vue-router';
import { AUTH_STORE } from '@/stores/modules/auth/auth.constants';

export default defineComponent({
  name: 'Order',
  components: {},
  setup() {
    const store = useStore(key);
    const orders = computed(
      () => store.getters[ORDER_STORE.GETTERS.ORDERS],
    );
    onMounted(async () => {
      await store.dispatch(ORDER_STORE.ACTIONS.LOAD_ORDERS);
      console.log(orders);
    });
    onBeforeRouteLeave(async (to) => {
      if (to.path === '/login') await store.dispatch(AUTH_STORE.ACTIONS.LOGOUT);
    });
    return {
      orders,
    };
  },
});
