import { ActionTree } from 'vuex';
import { OrderActionsTypes, OrderStateTypes } from '@/stores/modules/order/order.interface';
import { IRootState } from '@/stores/interfaces';
import { Order } from '@/models/order.model';
import OrdersService from '@/services/orders.service';
import { ResponseModel } from '@/models/response.model';
import { ORDER_STORE } from '@/stores/modules/order/order.constants';

export const orderActions: ActionTree<OrderStateTypes, IRootState> & OrderActionsTypes = {
  async [ORDER_STORE.ACTIONS.LOAD_ORDERS]({ commit }): Promise<void> {
    const response = await new OrdersService<ResponseModel<Array<Order>>>().get();
    commit(ORDER_STORE.MUTATIONS.SET_ORDERS, response.data.data);
  },
};

export default orderActions;
