import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "tw-h-min-[50rem] tw-w-[50rem]" }
const _hoisted_2 = { class: "tw-pb-[2rem] tw-text-[1.4rem]" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_generic_button = _resolveComponent("generic-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.text), 1),
    _createVNode(_component_generic_button, {
      class: "tw-mb-[2rem] tw-w-[5rem]",
      "margin-right": 10,
      height: 25,
      text: 'Ja',
      "on-click": _ctx.yes
    }, null, 8, ["on-click"]),
    _createVNode(_component_generic_button, {
      class: "tw-w-[5rem]",
      height: 25,
      text: 'Nein',
      "on-click": _ctx.no
    }, null, 8, ["on-click"])
  ]))
}