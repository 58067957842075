import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0c25b80c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "tw-h-full tw-min-w-[4rem] tw-w-[4rem] tw-relative"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GenericIcon = _resolveComponent("GenericIcon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (!_ctx.hide)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          onMouseenter: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.mouseEnterHandler && _ctx.mouseEnterHandler(...args))),
          onMouseleave: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.mouseLeaveHandler && _ctx.mouseLeaveHandler(...args))),
          class: "test tw-z-[16] tw-w-full tw-h-full tw-bg-primary-light tw-top-0 tw-pt-[0.5rem] tw-left-0 tw-absolute tw-flex tw-flex-col tw-transition-all tw-duration-300"
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.links, (link) => {
            return (_openBlock(), _createBlock(_component_router_link, {
              class: "tw-inline-flex tw-items-center tw-h-[3rem] tw-text-[2rem] tw-text-left tw-text-secondary tw-justify-start",
              to: link.path,
              key: link.name
            }, {
              default: _withCtx(() => [
                _createVNode(_component_GenericIcon, {
                  class: "tw-ml-[1rem] tw-mr-[1rem]",
                  bc: _ctx.currentPath.includes(link.path)? 'tertiary' : 'secondary' ,
                  src: link.icon
                }, null, 8, ["bc", "src"]),
                _createElementVNode("p", {
                  class: _normalizeClass(["tw-opacity-0", [{showLink: _ctx.isEnter}, {activeLink: _ctx.currentPath.includes(link.path)}]])
                }, _toDisplayString(link.name), 3)
              ]),
              _: 2
            }, 1032, ["to"]))
          }), 128))
        ], 32)
      ]))
    : _createCommentVNode("", true)
}