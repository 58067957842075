

import { defineComponent, ref } from 'vue';
import { useStore } from 'vuex';
import { key } from '@/stores';
import GenericButton from '@/components/generic/GenericButton.vue';
import { submitPUT } from '@/models/presentation.model';

export default defineComponent({
  components: { GenericButton },
  setup() {
    const store = useStore(key);

    const input = ref<string>('');

    const msg = ref<string>('Abschicken');

    const isFocus = ref<boolean>(false);

    const error = ref<boolean>(false);

    function onFocusHandler() {
      isFocus.value = true;
      error.value = false;
      msg.value = 'Abschicken';
    }

    function onBlurHandler() {
      isFocus.value = false;
      if (input.value === '') {
        error.value = true;
        msg.value = 'kein leeres Namensfeld';
      } else {
        msg.value = 'Abschicken';
        error.value = false;
      }
    }

    async function submit(event: Event) {
      event.preventDefault();
      let isToSubmit = true;
      if (error.value) return;
      // validation on click
      if (input.value === '') {
        error.value = true;
        isToSubmit = false;
      }

      if (isToSubmit) {
        try {
          const viewNames = store.getters['presentation/activePresentationViewNames'];
          const viewId = store.getters['presentation/activeView'];
          const view = viewNames[viewId];
          const data = submitPUT();
          await store.dispatch({
            type: 'setIsFetching',
            data: true,
          });
          await store.dispatch({
            type: 'presentation/saveTemplate',
            view,
            name: input.value,
            data,
          });
        } catch (e: any) {
          console.log(e.message);
        } finally {
          await store.dispatch({
            type: 'setIsFetching',
            data: false,
          });
        }
      } else {
        msg.value = 'kein leeres Namensfeld';
      }
    }

    return {
      input,
      msg,
      submit,
      isFocus,
      onBlurHandler,
      onFocusHandler,
      error,
    };
  }

  ,
});
