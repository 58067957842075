import { createApp } from 'vue';
import './index.css';
import axios from 'axios';
import VueAxios from 'vue-axios';
import { key, store } from '@/stores';
import App from './App.vue';
import router from './router';

const app = createApp(App);
app.use(VueAxios, axios);
app.use(store, key);
app.use(router);
app.mount('#app');
