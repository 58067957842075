

import {
  computed, defineComponent,
  reactive, ref,
} from 'vue';
import { useStore } from 'vuex';
import { key } from '@/stores';
import GenericButton from '@/components/generic/GenericButton.vue';
import useUpdateViewPresentation from '@/customHooks/use-update-view-presentation';
import router from '@/router';
import RoutesEnum from '@/routes.enum';

export default defineComponent({
  components: { GenericButton },
  setup() {
    const { updateView } = useUpdateViewPresentation();
    const store = useStore(key);
    // eslint-disable-next-line camelcase
    const inputs = reactive<{ name: string, notice: string | null, is_public?: boolean, customer_name: string | null, client_id: string | number | null}>({
      name: '',
      notice: '',
      customer_name: '',
      client_id: null,
    });

    const msg = ref<string>('Abschicken');
    const fileName = ref<string>('');
    const rawData = ref<string|ArrayBuffer>('');

    const isFocus = reactive({
      name: false,
      notice: false,
      customer_name: false,
      client_id: false,
    });

    const error = reactive({
      name: false,
      client_id: false,
    });

    const companyNames = computed(() => store.getters['presentation/companyNames']);

    const checked = ref<boolean>(false);

    function onFocusHandler(event: Event) {
      const elem = event.target as HTMLInputElement | null;
      if (elem) {
        const focusProp = elem.id.replace('new-', '') as 'name' | 'notice' | 'customer_name' | 'client_id';
        console.log(focusProp);
        isFocus[focusProp] = true;
        if (Object.keys(error)
          .includes(focusProp)) {
          error[focusProp as 'name' | 'client_id'] = false;
        }
      }
      const errorValues = Object.values(error);
      msg.value = errorValues.includes(true) ? 'rote Felder ausfüllen' : 'Abschicken';
    }

    function onBlurHandler(event: Event) {
      const elem = event.target as HTMLInputElement | null;
      if (elem) {
        const focusProp = elem.id.replace('new-', '') as 'name' | 'notice' | 'customer_name' | 'client_id';
        console.log(focusProp);
        isFocus[focusProp] = false;
        if (inputs[focusProp] === '' || inputs[focusProp] === null) {
          if (Object.keys(error)
            .includes(focusProp)) {
            error[focusProp as 'name' | 'client_id'] = true;
          }
        }
        const errorValues = Object.values(error);
        msg.value = errorValues.includes(true) ? 'rote Felder ausfüllen' : 'Abschicken';
      }
    }

    function onFileSelect(e:Event) {
      e.preventDefault();
      const input = document.getElementById('new-logo');
      input?.click();
    }

    async function onFileInputChange(e:Event) {
      const target = e.target as HTMLInputElement | null;
      if (target && target.files) {
        fileName.value = target.files[0].name;
        const fileReader = new FileReader();
        fileReader.readAsDataURL(target.files[0]);
        const result = await new Promise<string | ArrayBuffer>((resolve, _) => {
          fileReader.onload = (loadedFile) => {
            if (loadedFile.target !== null) {
              resolve(loadedFile.target.result as string | ArrayBuffer);
            } else {
              resolve('');
            }
          };
        });
        rawData.value = result;
        console.log(typeof result);
      }
    }

    async function submit(event: Event) {
      event.preventDefault();
      let isToSubmit = true;
      const errorValues = Object.values(error);
      if (errorValues.includes(true)) return;
      const inputProps = Object.keys(error);
      // validation
      inputProps.forEach((item) => {
        const itemCast = item as 'name' | 'client_id';
        if (inputs[itemCast] === '' || inputs[itemCast] === null) {
          error[itemCast] = true;
          isToSubmit = false;
        }
      });
      console.log(inputs);
      // go to add
      if (isToSubmit) {
        try {
          const payload = { ...inputs };
          if (payload.notice === '') payload.notice = null;
          if (payload.customer_name === '') payload.customer_name = null;
          payload.is_public = checked.value;
          payload.client_id = String(payload.client_id); // server requeremnt
          await updateView();
          await store.dispatch({
            type: 'presentation/newPresentation',
            data: payload,
            rawData: rawData.value,
          });
          await router.replace(`${RoutesEnum.PRESENTATION}/${store.getters['presentation/activePresentationId']}`);
        } catch (e: any) {
          console.log(e.message);
        } finally {
          await store.dispatch({
            type: 'setIsFetching',
            data: false,
          });
        }
      } else {
        msg.value = 'rote Felder ausfüllen';
      }
    }

    return {
      inputs,
      msg,
      fileName,
      companyNames,
      onFileSelect,
      onFileInputChange,
      submit,
      isFocus,
      onBlurHandler,
      onFocusHandler,
      error,
      checked,
    };
  }

  ,
});
