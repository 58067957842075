
import { defineComponent, computed } from 'vue';
import { useStore } from 'vuex';
import { key } from '@/stores';
import GenericButton from '@/components/generic/GenericButton.vue';
import PresentationFormNew from '@/pages/Presentation/PresentationFormNew.vue';
import PresentationFormAdd from '@/pages/Presentation/PresentationFormAdd.vue';
import ModalDeleteWindow from '@/components/modals/ModalDeleteWindow.vue';
import PrintService from '@/services/print.service';
import PresentationFormSaveTemplate from '@/pages/Presentation/PresentationFormSaveTemplate.vue';
import PresentationFormEditPresentation
  from '@/pages/Presentation/PresentationFormEditPresentation.vue';
import RoutesEnum from '@/routes.enum';
import { useRouter } from 'vue-router';

export default defineComponent(
  {
    components: {
      PresentationFormEditPresentation,
      PresentationFormSaveTemplate,
      ModalDeleteWindow,
      PresentationFormAdd,
      PresentationFormNew,
      GenericButton,
    },
    setup() {
      const store = useStore(key);
      const router = useRouter();

      function returnToAddModal() {
        store.dispatch({
          type: 'presentation/modalShow',
          data: 'add',
        });
      }

      function close() {
        store.dispatch({
          type: 'presentation/modalShow',
          data: false,
        });
      }
      // TODO: delete several template
      // TODO: delete also ids locally
      async function deleteTemplate() {
        console.log('deleteTemplate');
        try {
          await store.dispatch({
            type: 'setIsFetching',
            data: true,
          });
          await store.dispatch('presentation/deleteTemplate');
        } catch (e: any) {
          console.log(e.message);
        } finally {
          await store.dispatch({
            type: 'presentation/modalShow',
            data: false,
          });
          await store.dispatch({
            type: 'setIsFetching',
            data: false,
          });
        }
      }

      async function deleteView() {
        const viewId = store.getters['presentation/activeView'];
        const activePresentationId = store.getters['presentation/activePresentationId'];
        const changeToId = store.getters['presentation/changeToView'];
        try {
          console.log('deleteView');
          await store.dispatch({
            type: 'setIsFetching',
            data: true,
          });
          await new PrintService().id(viewId)
            .delete();
          await store.dispatch({
            type: 'presentation/modalShow',
            data: false,
          });
          await store.dispatch({
            type: 'presentation/getPresentation',
            id: activePresentationId,
            viewId: changeToId,
          });
        } catch (e: any) {
          console.log(e.message);
          await store.dispatch({
            type: 'presentation/modalShow',
            data: false,
          });
        } finally {
          await store.dispatch({
            type: 'setIsFetching',
            data: false,
          });
        }
      }
      async function deletePresentation() {
        try {
          await store.dispatch({
            type: 'setIsFetching',
            data: true,
          });
          const routeTo = await store.dispatch('presentation/deletePresentation');
          if (!routeTo) {
            await router.replace(RoutesEnum.PRESENTATION);
          } else {
            await router.replace(`${RoutesEnum.PRESENTATION}/${store.getters['presentation/activePresentationId']}`);
          }
        } catch (e:any) {
          console.log(e.message);
        } finally {
          await store.dispatch({
            type: 'presentation/modalShow',
            data: false,
          });
          await store.dispatch({
            type: 'setIsFetching',
            data: false,
          });
        }
      }
      const modalInfo = computed(() => store.getters['presentation/modalShow']);
      return {
        close,
        returnToAddModal,
        modalInfo,
        deleteView,
        deleteTemplate,
        deletePresentation,
      };
    },
  },
);
