
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'GenericErrorTextField',
  props: {
    text: {
      type: String,
      default: '',
    },
  },
});
