import { vModelText as _vModelText, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a750013a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tw-mb-4" }
const _hoisted_2 = ["placeholder"]
const _hoisted_3 = {
  key: 0,
  class: "tw-h-4 tw-my-4 tw-text-tertiary"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("input", {
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
      ref: "inputRef",
      class: _normalizeClass(["tw-border tw-border-[2px] tw-border-primary-light tw-pl-4 tw-rounded-lg tw-placeholder-secondary", [{ error: (!_ctx.isValid && _ctx.enableFocus) || _ctx.borderToggle }]]),
      style: _normalizeStyle({ height: _ctx.height, width: _ctx.width }),
      onFocus: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.focusHandler && _ctx.focusHandler(...args))),
      onBlur: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.blurHandler && _ctx.blurHandler(...args))),
      onInput: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.inputChange && _ctx.inputChange(...args))),
      type: "password",
      placeholder: _ctx.placeholder
    }, null, 46, _hoisted_2), [
      [_vModelText, _ctx.value]
    ]),
    (_ctx.errorMsg)
      ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(this.msg), 1))
      : _createCommentVNode("", true)
  ]))
}