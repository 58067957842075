import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-188d46d2"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "tw-flex tw-w-[40rem] tw-flex-col tw-items-center tw-m-[1rem]"
}
const _hoisted_2 = { class: "tw-flex-grow tw-flex tw-flex-col tw-items-center tw-overflow-y-auto tw-mb-[2rem] tw-w-full" }
const _hoisted_3 = ["onDragenter", "onDragstart", "onDragend", "onClick"]
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "tw-min-h-[6.5rem] tw-w-full tw-bg-secondary tw-p-[2rem] tw-rounded-[0.5rem]" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_generic_button = _resolveComponent("generic-button")!

  return (_ctx.noData)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.views, (item) => {
            return (_openBlock(), _createElementBlock("div", {
              onDragenter: ($event: any) => (_ctx.dragEnter(item)),
              onDragstart: ($event: any) => (_ctx.dragStart(item)),
              onDragend: ($event: any) => (_ctx.dragEnd(item)),
              draggable: "true",
              class: _normalizeClass(["tw-flex tw-justify-center tw-items-center tw-w-[14rem] tw-h-[10rem] tw-h-min-[5rem] tw-text-[2rem] tw-flex-shrink-0 tw-border-[2px] tw-border-primary tw-cursor-pointer tw-mb-[1rem] hover:tw-bg-primary-light tw-text-tertiary", [{bcView: item === _ctx.activeView},{onDrag: item === _ctx.draggedId} ]]),
              onClick: ($event: any) => (_ctx.selectView(item)),
              key: item
            }, [
              _createElementVNode("img", {
                class: "tw-h-[9rem]",
                src: _ctx.thumbnails[item].data
              }, null, 8, _hoisted_4)
            ], 42, _hoisted_3))
          }), 128))
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_generic_button, {
            height: 25,
            text: 'Hinzufügen',
            "margin-right": 8,
            "on-click": _ctx.addView
          }, null, 8, ["on-click"]),
          (_ctx.deletePermission)
            ? (_openBlock(), _createBlock(_component_generic_button, {
                key: 0,
                class: _normalizeClass([{disable: !_ctx.activeView}]),
                height: 25,
                text: 'Löschen',
                "margin-right": 8,
                "on-click": _ctx.deleteView
              }, null, 8, ["class", "on-click"]))
            : _createCommentVNode("", true),
          _createVNode(_component_generic_button, {
            height: 25,
            text: 'Neue Vorlage',
            "margin-right": 8,
            class: _normalizeClass([{disable: !_ctx.activeView}]),
            "on-click": _ctx.saveTemplate
          }, null, 8, ["class", "on-click"]),
          _createVNode(_component_generic_button, {
            height: 25,
            text: 'Duplizieren',
            "margin-right": 0,
            class: _normalizeClass([{disable: !_ctx.activeView}]),
            "on-click": _ctx.duplicateView
          }, null, 8, ["class", "on-click"])
        ])
      ]))
    : _createCommentVNode("", true)
}