
import { computed, defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'InputPassword',
  props: {
    placeholder: {
      type: String,
    },
    errorMsg: {
      type: String,
    },
    validator: {
      type: Function,
    },
    enableFocus: {
      type: Boolean,
    },
    heightProp: {
      type: String,
      required: true,
    },
    widthProp: {
      type: String,
      required: true,
    },
    borderToggle: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const isFocus = ref<boolean>(false);
    const isValid = ref<boolean>(true);
    const value = ref<string>('');

    function focusHandler() {
      isFocus.value = true;
      isValid.value = true;
      emit('focus');
    }

    function validate() {
      isValid.value = !(props.validator && props.validator(value.value));
    }

    function blurHandler() {
      isFocus.value = false;
      validate();
    }

    function inputChange() {
      emit('inputChange');
    }

    const height = computed<string>(() => `${props.heightProp}rem`);
    const width = computed<string>(() => `${props.widthProp}rem`);
    const msg = computed<any>(() => (isValid.value ? null : props.errorMsg));

    return {
      isFocus,
      isValid,
      value,
      focusHandler,
      validate,
      blurHandler,
      height,
      width,
      msg,
      inputChange,
    };
  },
});
