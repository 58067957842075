interface Observer {
  root: HTMLElement | null,
  rootMargin: string,
  threshold: number
}

export interface AllImages<T> {
  [key: string]: T;
}

export interface AllText<T> {
  [key: string]: T;
}

export interface SubmitObj {
  [key: string]: Element | string;
}

export interface ImageHandler {
  imgElem: HTMLImageElement,
  imgContainer: HTMLDivElement,
  imgFallback: HTMLDivElement,
  observer: IntersectionObserver,
  observerOpt: Observer,
  startX: number,
  startY: number,
  isIntersecting: boolean,
  isImgRemoved: boolean,
  isMoved: boolean,
  currentX: number,
  currentY: number,
  prevX: number,
  prevY: number
  scale: number,
  dx: () => number,
  dy: () => number,
  zoomIn: () => number,
  zoomOut: () => number,
  reset: () => void,
  onHardReset: () => void,
  onUnmount: () => void,
  scaleImgId: (event: WheelEvent) => void,
  onImgMoveId: (event: MouseEvent) => void,
  onClickImgReleaseId: (event: MouseEvent) => void,
  onImgClickId: (event: MouseEvent) => void,
  onMouseEnterImgContainerId: () => void,
  onMouseLeaveImgContainerId: () => void,
}

export interface TextHandler {
  textElem: HTMLDivElement;
  range: Range;
  rightClickTextFieldId: (event: Event) => void;
  boldHandlerId: (event: Event) => void;
}

export interface YtContainer {
  videoContainer: HTMLDivElement;
  videoFallback: HTMLDivElement;
  videoElem: HTMLIFrameElement;
  btn: HTMLButtonElement;
  init: () => YtContainer;
  onUnmount: () => YtContainer;
  addOnEnterLeaveListeners: () => YtContainer;
  onEnterShowButton: (event: MouseEvent) => void;
  onLeaveHideButton: (event: MouseEvent) => void;
  addOnClickButtonListener: (handler: (show: boolean) => void) => YtContainer;
  removeOnClickButtonListener: (handler: (show: boolean) => void) => YtContainer;
  addYtLink: (link:string)=>void,
  editYtLink: (link:string)=>void,
}

export class Imig implements ImageHandler {
  imgElem: HTMLImageElement;

  imgContainer: HTMLDivElement;

  imgFallback: HTMLDivElement;

  observerOpt: Observer;

  observer: IntersectionObserver;

  startX = 0;

  startY = 0;

  isIntersecting = true;

  isMoved = false;

  currentX = 0;

  currentY = 0;

  prevX = 0;

  prevY = 0;

  scale = 1;

  isImgRemoved = false;

  zoomIn() {
    this.scale += 0.05;
    return this.scale;
  }

  zoomOut() {
    this.scale -= 0.05;
    return this.scale;
  }

  dx() {
    return this.currentX - this.startX + this.prevX;
  }

  dy() {
    return this.currentY - this.startY + this.prevY;
  }

  reset() {
    this.prevX = 0;
    this.prevY = 0;
    this.scale = 1;
  }

  // eslint-disable-next-line class-methods-use-this
  scaleImgId(event: WheelEvent) {
    console.log(event);
  }

  // eslint-disable-next-line class-methods-use-this
  onImgMoveId(event: MouseEvent) {
    console.log(event);
  }

  // eslint-disable-next-line class-methods-use-this
  onClickImgReleaseId(event: MouseEvent) {
    console.log(event);
  }

  // eslint-disable-next-line class-methods-use-this
  onImgClickId(event: MouseEvent) {
    console.log(event);
  }

  // eslint-disable-next-line class-methods-use-this,@typescript-eslint/no-empty-function
  onMouseEnterImgContainerId() {

  }

  // eslint-disable-next-line class-methods-use-this,@typescript-eslint/no-empty-function
  onMouseLeaveImgContainerId() {

  }

  onHardReset() {
    this.reset();
    this.imgElem.style.transform = 'scale(1)';
    this.imgElem.style.top = '0px';
    this.imgElem.style.left = '0px';
  }

  onUnmount() {
    this.imgElem.removeEventListener('mousedown', this.onImgClickId);
    this.imgContainer.removeEventListener('mouseenter', this.onMouseEnterImgContainerId);
    this.imgContainer.removeEventListener('mouseleave', this.onMouseLeaveImgContainerId);
    this.imgContainer.removeEventListener('wheel', this.scaleImgId);
    document.removeEventListener('mousemove', this.onImgMoveId);
    document.removeEventListener('mouseup', this.onClickImgReleaseId);
  }

  constructor(container: HTMLDivElement, fallbackClass: string) {
    this.imgContainer = container;
    // eslint-disable-next-line prefer-destructuring
    this.imgElem = this.imgContainer.getElementsByTagName('img')[0];
    this.imgFallback = this.imgContainer.querySelector(fallbackClass) as HTMLDivElement;
    this.observerOpt = {
      root: container,
      rootMargin: '0px',
      threshold: 0,
    };
    // re-init scale
    if (this.imgElem.style.transform !== '') {
      const parsed = this.imgElem.style.transform.match(/\d+.\d+/);
      if (parsed) {
        this.scale = Number(parsed[0]);
      }
    }
    // re-init top
    if (this.imgElem.style.top !== '') {
      const parsed = this.imgElem.style.top.replace('px', '');
      this.prevY = Number(parsed);
    }

    // re-init left
    if (this.imgElem.style.left !== '') {
      const parsed = this.imgElem.style.left.replace('px', '');
      this.prevX = Number(parsed);
    }

    function observerHandler(this: ImageHandler, e: IntersectionObserverEntry[]) {
      const { isIntersecting } = e[0];
      this.isIntersecting = isIntersecting;
    }

    // start observing on init
    this.observer = new IntersectionObserver(observerHandler.bind(this), this.observerOpt);
    this.observer.observe(this.imgElem);
  }
}

// TODO: here i upload html related to youtube video
export function submitPUT() {
  const obj: SubmitObj = {};
  const attrs = Array.from(document.querySelectorAll('[jsonkey]'));
  attrs.forEach((item: Element) => {
    const atr = item.getAttribute('jsonkey');
    if (atr) {
      if (item.nodeName === 'IMG' || item.nodeName === 'IFRAME') {
        obj[atr] = item.outerHTML; // get img as string or iframe as string
      } else {
        obj[atr] = item.innerHTML;
      }
    }
  });
  return obj;
}
