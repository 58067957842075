
import PresentationService from '@/services/presentation.service';
import {
  computed, defineComponent, ref,
} from 'vue';
import { useStore } from 'vuex';
import { key } from '@/stores';
import useCurrentUrl from '@/customHooks/use-current-url';
import GenericButton from '@/components/generic/GenericButton.vue';
import useUpdateViewPresentation from '@/customHooks/use-update-view-presentation';
import { useRouter } from 'vue-router';
import RoutesEnum from '@/routes.enum';

export default defineComponent({
  components: { GenericButton },
  setup() {
    const {
      hide,
      currentPath,
    } = useCurrentUrl([RoutesEnum.LOGIN, RoutesEnum.NOT_FOUND]);

    const router = useRouter();
    const store = useStore(key);
    const { updateView } = useUpdateViewPresentation();
    const noData = computed(() => store.getters['presentation/noData']);
    const presentationName = computed(() => store.getters['presentation/activePresentationName']);
    const presentations = computed(() => {
      const ids = store.getters['presentation/presentationIds'] as number[];
      const activeId = store.getters['presentation/activePresentationId'];
      return ids.indexOf(activeId) + 1;
    });
    const isFocus = ref<boolean>(false);
    const searchTerm = ref<string>('');
    const searchPresentations = ref<Array<{ id: string; name: string; }>>([]);
    const numOfPresentations = computed(() => store.getters['presentation/presentationIds'].length);

    async function changePresentation(direction: string) {
      const ids = store.getters['presentation/presentationIds'] as number[];
      const activeId = store.getters['presentation/activePresentationId'];
      try {
        const index = ids.indexOf(activeId);
        const nextId = ids[index + 1];
        const prevId = ids[index - 1];
        if (direction === 'next') {
          if (index + 1 < ids.length) {
            await updateView();
            await store.dispatch({
              type: 'presentation/resetActiveId',
              id: nextId,
            });
            await store.dispatch({
              type: 'presentation/getPresentation',
              id: nextId,
            });
            await router.replace(`${RoutesEnum.PRESENTATION}/${nextId}`);
          }
        } else if (direction === 'prev') {
          if (index + 1 > 1) {
            await updateView();
            await store.dispatch({
              type: 'presentation/resetActiveId',
              id: prevId,
            });
            await store.dispatch({
              type: 'presentation/getPresentation',
              id: prevId,
            });
            await router.replace(`${RoutesEnum.PRESENTATION}/${prevId}`);
          }
        } else {
          throw new Error('direction incorrect');
        }
      } catch (e: any) {
        console.log(e.message);
      } finally {
        await store.dispatch({
          type: 'setIsFetching',
          data: false,
        });
      }
    }

    let debounceId = -1;

    function search() {
      clearTimeout(debounceId);
      debounceId = setTimeout(async () => {
        if (searchTerm.value !== '') {
          try {
            const response: any = await new PresentationService().search(searchTerm.value)
              .get();
            searchPresentations.value = response.data.data.data;
          } catch (e: any) {
            console.log(e.message);
          }
        } else {
          searchPresentations.value = [];
        }
      }, 500);
    }

    let blurId = -1;

    // search
    function onBlur() {
      // debounce blur
      blurId = setTimeout(() => {
        isFocus.value = false;
      }, 500);
    }

    function onFocus() {
      clearTimeout(blurId);
      isFocus.value = true;
    }

    async function goToPresentation(id: string) {
      try {
        const activePresentationId = store.getters['presentation/activePresentationId'];
        if (activePresentationId === id) throw new Error('same presentation id');
        await updateView();
        await store.dispatch({
          type: 'presentation/resetActiveId',
          id,
        });
        await store.dispatch({
          type: 'presentation/getPresentation',
          id,
        });
        searchTerm.value = '';
        searchPresentations.value = [];
        await router.replace(`${RoutesEnum.PRESENTATION}/${id}`);
      } catch (e: any) {
        console.log(e.message);
      } finally {
        await store.dispatch({
          type: 'setIsFetching',
          data: false,
        });
      }
    }

    return {
      hide,
      currentPath,
      presentations,
      numOfPresentations,
      changePresentation,
      noData,
      presentationName,
      onFocus,
      onBlur,
      isFocus,
      search,
      searchTerm,
      searchPresentations,
      goToPresentation,
    };
  },
});
