
import { defineComponent } from 'vue';
import useCurrentUrl from '@/customHooks/use-current-url';
import RoutesEnum from '@/routes.enum';

export default defineComponent({
  components: {},
  setup() {
    const { hide } = useCurrentUrl([RoutesEnum.LOGIN, RoutesEnum.NOT_FOUND]);
    return {
      hide,
    };
  },
});

