import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Login from '@/pages/Login.vue';
import Order from '@/pages/Order/Order.vue';
import Presentation from '@/pages/Presentation/Presentation.vue';
import isAuth from '@/models/auth.model';
import RoutesEnum from '@/routes.enum';
import NotFound from './pages/NotFound.vue';

// each module has it's own main route for now
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: RoutesEnum.LOGIN,
  },
  {
    path: RoutesEnum.LOGIN,
    component: Login,
    meta: { requiresUnAuth: true },
  },
  {
    path: RoutesEnum.ORDER,
    component: Order,
    meta: {
      requiresAuth: true,
      name: 'Aufträge',
    },
  },
  {
    path: RoutesEnum.NOT_FOUND,
    component: NotFound,
  },
  {
    path: RoutesEnum.PRESENTATION,
    component: Presentation,
    meta: {
      requiresAuth: true,
      name: 'Präsentation',
    },
  },
  {
    path: `${RoutesEnum.PRESENTATION}/:id`,
    component: Presentation,
    meta: {
      requiresAuth: true,
      name: 'Präsentation',
    },
  },
  {
    path: '/:catchAll(.*)',
    redirect: RoutesEnum.NOT_FOUND,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, prev, next) => {
  if (!isAuth() && to.meta.requiresAuth) {
    next(RoutesEnum.LOGIN);
  } else if (isAuth() && to.meta.requiresUnAuth) {
    next(RoutesEnum.PRESENTATION);
  } else {
    next();
  }
});

export default router;
