
import { useStore } from 'vuex';
import { computed, defineComponent } from 'vue';
import useCurrentUrl from '@/customHooks/use-current-url';
import GenericButton from '@/components/generic/GenericButton.vue';
import { key } from '@/stores';
import useUpdateViewPresentation from '@/customHooks/use-update-view-presentation';
import RoutesEnum from '@/routes.enum';
import router from '@/router';

export default defineComponent({
  components: { GenericButton },
  setup() {
    const {
      hide,
      currentPath,
    } = useCurrentUrl([RoutesEnum.LOGIN, RoutesEnum.NOT_FOUND]);
    const store = useStore(key);
    const noData = computed(() => store.getters['presentation/noData']);
    const publicUrl = computed(() => store.getters['presentation/publicUrl']);
    const activeViewId = computed(() => store.getters['presentation/activeView']);
    const deletePermission = computed(() => store.getters['presentation/deletePermission']);
    const activePresentationId = computed(() => store.getters['presentation/activePresentationId']);
    const viewIds = computed(() => store.getters['presentation/viewIds']);
    const { updateView } = useUpdateViewPresentation();

    async function newPresentation() {
      try {
        await store.dispatch('presentation/getCompanyNames');
        await store.dispatch({
          type: 'presentation/modalShow',
          data: 'new',
        });
      } catch (e: any) {
        console.log(e.message);
      }
    }

    async function editPresentation() {
      try {
        await store.dispatch('presentation/getCompanyNames');
        await store.dispatch({
          type: 'presentation/modalShow',
          data: 'edit',
        });
      } catch (e: any) {
        console.log(e.message);
      }
    }

    async function printPdf() {
      try {
        await store.dispatch({
          type: 'setIsFetching',
          data: true,
        });
        await updateView();
        await store.dispatch('presentation/printPDF');
      } catch (e: any) {
        console.log(e.message);
      } finally {
        await store.dispatch({
          type: 'setIsFetching',
          data: false,
        });
      }
    }

    async function duplicatePresentation() {
      try {
        await store.dispatch({
          type: 'setIsFetching',
          data: true,
        });
        console.log(activeViewId.value);
        await updateView();
        await store.dispatch('presentation/duplicatePresentation', { id: activePresentationId.value });
        await router.replace(`${RoutesEnum.PRESENTATION}/${store.getters['presentation/activePresentationId']}`);
        console.log(activeViewId.value);
      } catch (e:any) {
        console.log(e.message);
      } finally {
        await store.dispatch({
          type: 'setIsFetching',
          data: false,
        });
      }
    }

    async function deletePresentation() {
      try {
        await store.dispatch({
          type: 'presentation/modalShow',
          data: 'deletePresentation',
        });
      } catch (e:any) {
        console.log(e.message);
      }
    }

    function clearFieldOnEmptyTextField() {
      const res = document.getElementById('presentation-text-2');
      if (res) {
        // eslint-disable-next-line no-restricted-syntax
        for (const element of res.children) {
          if (!element.textContent) {
            if (element.children[0]) {
              element.removeChild(element.children[0]);
            }
          }
        }
      } else {
        const pres10 = document.getElementsByClassName('presentation-10');
        if (pres10.length) {
          const header = document.getElementById('presentation-bar');
          if (header) {
          // check for paragraph
            const par = header.getElementsByTagName('p');
            console.log(par);
            if (par.length) {
            //  remove <p>
              header.removeChild(par[0]);
            } else {
              // add <p>
              header.appendChild(document.createElement('p'));
            }
          }
        }
      }
      // TODO: add paragraph or not to hide subtitel in pres-10 using js
    }

    return {
      hide,
      currentPath,
      newPresentation,
      noData,
      editPresentation,
      printPdf,
      publicUrl,
      activeViewId,
      deletePresentation,
      deletePermission,
      duplicatePresentation,
      viewIds,
      clearFieldOnEmptyTextField,
    };
  },
});
