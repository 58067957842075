
import { defineComponent, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { key } from '@/stores/index';
import { AUTH_STORE } from '@/stores/modules/auth/auth.constants';
import RoutesEnum from '@/routes.enum';
import routesEnum from '@/models/routes.model';

export default defineComponent({
  setup() {
    const store = useStore(key);
    const route = useRoute();
    const router = useRouter();
    const url = computed(() => routesEnum.getHeading(route.fullPath));

    const isLogin = computed(() => store.getters[AUTH_STORE.GETTERS.TOKEN]);

    // logout handler
    async function logout() {
      try {
        await router.push(RoutesEnum.LOGIN);
      } catch (e: any) {
        console.log(e.message);
      }
    }

    return {
      url,
      logout,
      isLogin,
    };
  },
});
