
import { computed, defineComponent } from 'vue';
import Spinner from '@/components/Spinner.vue';
import PageNavigation from '@/components/navigation/PageNavigation.vue';
import PageMenu from '@/components/navigation/PageMenu.vue';
import PageFooter from '@/components/navigation/PageFooter.vue';
import PageSideMenu from '@/components/navigation/PageSideMenu.vue';
import Snackbars from '@/components/Snackbars.vue';
import { useStore } from 'vuex';
import { key } from '@/stores';
import { AUTH_STORE } from '@/stores/modules/auth/auth.constants';
import router from '@/router';
import PresentationBackDropBusy from '@/pages/Presentation/PresentationBackDropBusy.vue';
import PageHeader from './components/navigation/PageHeader.vue';

export default defineComponent({
  components: {
    PresentationBackDropBusy,
    Snackbars,
    PageSideMenu,
    PageFooter,
    PageMenu,
    PageHeader,
    PageNavigation,
    Spinner,
  },
  setup() {
    const store = useStore(key);
    store.dispatch(AUTH_STORE.ACTIONS.TRY_LOGIN, { router, store })
      .catch((err: any) => console.log(err));
    const fetching = computed(() => store.getters.isFetching);

    return {
      fetching,
    };
  },
});
