import BaseService from '@/services/base.service';

export default class PrintService<T> extends BaseService<T> {
  // Set the resource route of the model
  resource = 'print';

  toEncode = ['+', '#'];

  views() {
    this.resource += '/views/presentation_custom';
    return this;
  }

  preview() {
    this.resource += '/preview';
    return this;
  }

  include(key: string, value: string | boolean | number) {
    this.resource += `?${key}=${value}`;
    return this;
  }

  filter() {
    this.resource += '?filter=is_template|1';
    return this;
  }

  search(term: string) {
    this.resource += `&search=${this.toEncode.includes(term) ? encodeURIComponent(term) : term}&sort=template_name|asc&per_page=499`;
    return this;
  }

  id(id: number) {
    this.resource += `/${id}`;
    return this;
  }
}
