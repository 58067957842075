

import {
  computed, defineComponent,
  reactive,
  ref,
} from 'vue';
import { useStore } from 'vuex';
import { key } from '@/stores';
import GenericButton from '@/components/generic/GenericButton.vue';
import useUpdateViewPresentation from '@/customHooks/use-update-view-presentation';

export default defineComponent({
  components: { GenericButton },
  setup() {
    const store = useStore(key);
    const { updateView } = useUpdateViewPresentation();

    // eslint-disable-next-line camelcase
    const inputs = reactive<{ name: string, notice: string, is_public?: boolean, customer_name: string | null, client_id: string | number | null }>({
      name: store.getters['presentation/activePresentationName'],
      notice: store.getters['presentation/notice'],
      customer_name: store.getters['presentation/customerName'],
      client_id: store.getters['presentation/clientId'],
    });

    const msg = ref<string>('Aktualisieren');
    const fileName = ref<string>('');
    const rawData = ref<string|ArrayBuffer>('');

    const isFocus = reactive({
      name: false,
      notice: false,
      customer_name: false,
      client_id: false,
    });

    const checked = ref<boolean>(!!store.getters['presentation/publicUrl']);

    const companyNames = computed(() => store.getters['presentation/companyNames']);

    function onFocusHandler(event: Event) {
      const elem = event.target as HTMLInputElement | null;
      msg.value = 'Aktualisieren';
      if (elem) {
        const focusProp = elem.id.replace('new-', '') as 'name' | 'notice' | 'customer_name' | 'client_id';
        isFocus[focusProp] = true;
      }
    }

    function onBlurHandler(event: Event) {
      const elem = event.target as HTMLInputElement | null;
      if (elem) {
        const focusProp = elem.id.replace('new-', '') as 'name' | 'notice' | 'customer_name' | 'client_id';
        isFocus[focusProp] = false;
      }
    }

    function onFileSelect(e:Event) {
      e.preventDefault();
      const input = document.getElementById('new-logo');
      input?.click();
    }

    async function onFileInputChange(e:Event) {
      const target = e.target as HTMLInputElement | null;
      if (target && target.files) {
        fileName.value = target.files[0].name;
        const fileReader = new FileReader();
        fileReader.readAsDataURL(target.files[0]);
        const result = await new Promise<string | ArrayBuffer>((resolve, _) => {
          fileReader.onload = (loadedFile) => {
            if (loadedFile.target !== null) {
              resolve(loadedFile.target.result as string | ArrayBuffer);
            } else {
              resolve('');
            }
          };
        });
        rawData.value = result;
        console.log(typeof result);
      }
    }

    async function submit(event: Event) {
      event.preventDefault();
      // validation
      // go to add
      try {
        await updateView();
        const payload = { ...inputs };
        payload.is_public = checked.value;
        payload.client_id = String(payload.client_id);
        await store.dispatch({
          type: 'presentation/editPresentation',
          data: payload,
          rawData: rawData.value,
        });
      } catch (e: any) {
        console.log(e.message);
      } finally {
        await store.dispatch({
          type: 'presentation/modalShow',
          data: false,
        });
        await store.dispatch({
          type: 'setIsFetching',
          data: false,
        });
      }
    }

    return {
      inputs,
      msg,
      submit,
      isFocus,
      onBlurHandler,
      onFocusHandler,
      onFileSelect,
      onFileInputChange,
      fileName,
      checked,
      companyNames,
    };
  }

  ,
});
