import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "tw-fixed tw-w-screen tw-h-screen tw-w-screen tw-z-[200] tw-top-[50%] tw-left-[50%] tw-translate-x-[-50%] tw-translate-y-[-50%]"
}
const _hoisted_2 = { class: "tw-bg-white tw-rounded-[2rem] tw-fixed tw-w-max-[60rem] tw-h-max-[40rem] tw-top-[50%] tw-left-[50%] tw-translate-x-[-50%] tw-translate-y-[-50%] tw-flex tw-flex-col tw-items-center" }
const _hoisted_3 = { class: "tw-flex-grow" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_generic_button = _resolveComponent("generic-button")!
  const _component_presentation_form_new = _resolveComponent("presentation-form-new")!
  const _component_presentation_form_add = _resolveComponent("presentation-form-add")!
  const _component_presentation_form_edit_presentation = _resolveComponent("presentation-form-edit-presentation")!
  const _component_presentation_form_save_template = _resolveComponent("presentation-form-save-template")!
  const _component_modal_delete_window = _resolveComponent("modal-delete-window")!

  return (_ctx.modalInfo)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          class: "tw-w-full tw-h-full tw-bg-primary tw-opacity-30",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
        }),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_generic_button, {
            class: "tw-mb-[3rem] tw-mt-[1rem] tw-rounded-[50%]",
            "margin-right": 10,
            text: 'X',
            "margin-left": 'auto',
            height: 26,
            "font-size": 12,
            "on-click": _ctx.close
          }, null, 8, ["on-click"]),
          _createElementVNode("div", _hoisted_3, [
            (_ctx.modalInfo === 'new')
              ? (_openBlock(), _createBlock(_component_presentation_form_new, { key: 0 }))
              : _createCommentVNode("", true),
            (_ctx.modalInfo === 'add')
              ? (_openBlock(), _createBlock(_component_presentation_form_add, { key: 1 }))
              : _createCommentVNode("", true),
            (_ctx.modalInfo === 'edit')
              ? (_openBlock(), _createBlock(_component_presentation_form_edit_presentation, { key: 2 }))
              : _createCommentVNode("", true),
            (_ctx.modalInfo === 'saveTemplate')
              ? (_openBlock(), _createBlock(_component_presentation_form_save_template, { key: 3 }))
              : _createCommentVNode("", true),
            (_ctx.modalInfo === 'delete')
              ? (_openBlock(), _createBlock(_component_modal_delete_window, {
                  key: 4,
                  no: _ctx.close,
                  yes: _ctx.deleteView,
                  text: 'Möchten Sie die Folie Löschen'
                }, null, 8, ["no", "yes"]))
              : _createCommentVNode("", true),
            (_ctx.modalInfo === 'deleteTemplate')
              ? (_openBlock(), _createBlock(_component_modal_delete_window, {
                  key: 5,
                  no: _ctx.returnToAddModal,
                  yes: _ctx.deleteTemplate,
                  text: 'Möchten Sie die Vorlage Löschen'
                }, null, 8, ["no", "yes"]))
              : _createCommentVNode("", true),
            (_ctx.modalInfo === 'deletePresentation')
              ? (_openBlock(), _createBlock(_component_modal_delete_window, {
                  key: 6,
                  no: _ctx.close,
                  yes: _ctx.deletePresentation,
                  text: 'Möchten Sie die Präsentation Löschen'
                }, null, 8, ["no", "yes"]))
              : _createCommentVNode("", true)
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}