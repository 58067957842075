export enum OrderGetters {
  ORDERS= 'ORDERS',
}

export enum OrderMutations {
  SET_ORDERS = 'SET_ORDERS',
}

export enum OrderActions {
  LOAD_ORDERS = 'LOAD_ORDERS'
}
