import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: "tw-w-fit tw-pl-[1rem] tw-pr-[1rem] tw-rounded-[0.5rem]",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.clickHandler && _ctx.clickHandler(...args))),
    style: _normalizeStyle(_ctx.style)
  }, _toDisplayString(_ctx.text), 5))
}