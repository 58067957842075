import { GetterTree } from 'vuex';
import { AuthGettersTypes, AuthStateTypes } from '@/stores/modules/auth/auth.interface';
import { IRootState } from '@/stores/interfaces';
import { AUTH_STORE } from '@/stores/modules/auth/auth.constants';

export const authGetters: GetterTree<AuthStateTypes, IRootState> & AuthGettersTypes = {
  [AUTH_STORE.GETTERS.TOKEN]: (state: AuthStateTypes) => state.token,
  [AUTH_STORE.GETTERS.ERROR_MSG]: (state: AuthStateTypes) => state.errorMsg,
  [AUTH_STORE.GETTERS.PERMISSIONS]: (state:AuthStateTypes) => state.permissions,
};

export default authGetters;
