import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("img", {
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args))),
    style: _normalizeStyle({width: _ctx.width}),
    src: _ctx.src,
    class: _normalizeClass(["tw-cursor-pointer", [{secondary: _ctx.bc === 'secondary', tertiary: _ctx.bc === 'tertiary'}]])
  }, null, 14, _hoisted_1))
}