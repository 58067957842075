
// TODO: add remove clear time-outs on page leave
import { useStore } from 'vuex';
import {
  defineComponent, onMounted,
  ref, reactive,
  watch, computed, onUnmounted, shallowRef,
} from 'vue';
// eslint-disable-next-line import/no-cycle
import { key } from '@/stores';
import {
  ImageHandler,
  TextHandler, Imig, AllImages, AllText, YtContainer,
} from '@/models/presentation.model';
import ModalPresentation from '@/components/modals/ModelPresentation.vue';
import PresentationViews from '@/pages/Presentation/PresentationViews.vue';
import useUpdateViewPresentation from '@/customHooks/use-update-view-presentation';
import TextContainer from '@/models/presentation.text.model';
import { AUTH_STORE } from '@/stores/modules/auth/auth.constants';
import { onBeforeRouteLeave, useRoute, useRouter } from 'vue-router';
import RoutesEnum from '@/routes.enum';
import Compressor from 'compressorjs';
import YouTubeVideoContainer from '@/models/presentation.youtube.model';
import PresentationFormAddEditYoutube
  from '@/pages/Presentation/PresentationFormAddEditYoutube.vue';

export default defineComponent({
  components: {
    PresentationFormAddEditYoutube,
    PresentationViews,
    ModalPresentation,
  },
  setup() {
    const store = useStore(key);
    const route = useRoute();
    const router = useRouter();
    const { updateView } = useUpdateViewPresentation(); // update view hook
    const hideScrollBar = ref<boolean>(false);
    const isImgClicked = ref<boolean>(false);
    const dropDownImg = ref<boolean>(false);
    const dropDownXImg = ref<number>(0);
    const dropDownYImg = ref<number>(0);
    const isImgRemove = ref<boolean>(false);
    const dropDownTextField = ref<boolean>(false);
    const dropDownXTextField = ref<number>(0);
    const dropDownYTextField = ref<number>(0);
    const activeTextField = ref<string>('');
    const showYouTubeModal = ref<boolean>(false);
    // for performance reasons - shallowRef - no need for deep watch
    const youTubeContainer = shallowRef<YtContainer | null>(null);
    const modalShow = computed(() => store.getters['presentation/modalShow']);
    const noData = computed(() => store.getters['presentation/noData']);
    const wysiwyg = reactive({
      bold: false,
      italic: false,
      underline: false,
      font32: false,
      font48: false,
    });

    let imgId = ''; // changes on img container enter
    // let clearSubscribeAction: () => void;

    // presentation html
    const presentation = computed(() => store.getters['presentation/test']);

    // dropdown enter scroll hide
    function onDropDownEnter(): void {
      hideScrollBar.value = true;
    }

    // dropdown leave display scroll bar if needed
    function onDropDownLeave(): void {
      hideScrollBar.value = false;
    }

    // custom contextmenu handler remove on click
    function hideCustomDropDown(): void {
      if (dropDownImg.value) {
        dropDownImg.value = false;
      }
      if (dropDownTextField.value) {
        dropDownTextField.value = false;
      }
    }

    // add dropdown handler
    document.addEventListener('click', hideCustomDropDown);

    // contextmenu handler disable on enter img and text container
    function disableContextMenu(event: Event): void {
      event.preventDefault();
    }

    // text field
    function clickHandlerTextField(event: Event): void {
      const value = event.target as HTMLDivElement;
      value.style.height = 'auto';
      value.style.height = `${value.scrollHeight}px`;
    }

    // container for all images
    const imgAll: AllImages<ImageHandler> = {};
    // text field obj
    const textAll: AllText<TextHandler> = {};
    // youtube obj

    // text handler
    function rightClickTextField(id: string, event: Event): void {
      // make selection italic, bold or underline
      const mouse = event as MouseEvent;
      if (mouse.button !== 2) return;
      // reset on right click
      Object.keys(wysiwyg)
        .forEach((item) => {
          wysiwyg[item as 'bold' | 'italic' | 'underline' | 'font32' | 'font48'] = false;
        });
      activeTextField.value = id;
      dropDownXTextField.value = mouse.x;
      dropDownYTextField.value = mouse.y;
      const selection = document.getSelection();
      if (selection?.rangeCount && textAll[id]) {
        textAll[id].range = selection.getRangeAt(0);
        // set what one can edit in text field
        const element = textAll[id].textElem;
        Object.keys(element.dataset)
          .forEach((item: string) => {
            wysiwyg[item as 'bold' | 'italic' | 'underline' | 'font32' | 'font48'] = true;
          });
        if (selection) {
          dropDownTextField.value = true;
          setTimeout(() => {
            if (textAll[id].range) {
              selection.removeAllRanges();
              selection.addRange(textAll[id].range);
            }
          }, 0);
        }
      }
    }

    // bold
    function boldHandler() {
      const selection = window.getSelection();
      if (selection) {
        selection.removeAllRanges();
        selection.addRange(textAll[activeTextField.value].range);
        const rez = document.execCommand('bold', false, undefined);
      }
    }

    // italic
    function italicHandler() {
      const selection = window.getSelection();
      if (selection) {
        selection.removeAllRanges();
        selection.addRange(textAll[activeTextField.value].range);
        const rez = document.execCommand('italic', false, undefined);
      }
    }

    // underline
    function underlineHandler() {
      const selection = window.getSelection();
      if (selection) {
        selection.removeAllRanges();
        selection.addRange(textAll[activeTextField.value].range);
        const rez = document.execCommand('underline', false, undefined);
      }
    }

    // font-size
    function fontChange(size: number) {
      const selection = window.getSelection();
      if (selection) {
        selection.removeAllRanges();
        selection.addRange(textAll[activeTextField.value].range);
        const span = document.createElement('span');
        span.textContent = textAll[activeTextField.value].range.toString();
        span.style.fontSize = `${size}px`;
        const rez = document.execCommand('insertHTML', false, span.outerHTML);
      }
    }

    // paste
    function pastePlaneText(event:any) {
      event.preventDefault();
      if (event?.clipboardData) {
        const planeText = event.clipboardData.getData('text/plain');
        document.execCommand('insertText', false, planeText);
      }
    }

    function onMouseEnterTextContainer() {
      window.addEventListener('contextmenu', disableContextMenu);
    }

    function onMouseLeaveTextContainer() {
      window.removeEventListener('contextmenu', disableContextMenu);
    }

    // file API
    function addImg(event: Event) {
      const target = event.target as HTMLInputElement | null;
      if (target) {
        // read files
        const imgFiles = target.files;
        if (imgAll[imgId].imgElem && imgFiles && FileReader) {
          // check the file size - if greater than x mb - include compression
          const fileSize = imgFiles[0].size / (1024 ** 2);
          if (fileSize > 2) {
            // eslint-disable-next-line no-new
            new Compressor(imgFiles[0], {
              quality: fileSize > 4 ? 0.2 : 0.6,
              success(result: File | Blob) {
                const compressedLoadFile = new FileReader();
                // eslint-disable-next-line no-return-assign
                compressedLoadFile.onload = function (data: any) {
                  if (imgAll[imgId].imgElem && imgAll[imgId].imgFallback) {
                    if (imgAll[imgId].imgElem.style.display === 'none') {
                      imgAll[imgId].imgElem.style.display = 'block';
                      imgAll[imgId].imgFallback.style.display = 'none';
                    }
                    imgAll[imgId].isImgRemoved = false;
                    imgAll[imgId].imgElem.src = data.target.result;
                    imgAll[imgId].onHardReset(); // read file - once finished - call onload
                  }
                };
                compressedLoadFile.readAsDataURL(result);
              },
              error: (_) => store.dispatch({ type: 'pushToErrorStack', error: { msg: 'local - Bild könnte nicht eingeladen werden - bitte versuchen Sie später', status: 101 } }),
            });
          } else {
            // no need for compression
            const loadFile = new FileReader();
            loadFile.onload = function (e: any) {
              if (imgAll[imgId].imgElem && imgAll[imgId].imgFallback) {
                if (imgAll[imgId].imgElem.style.display === 'none') {
                  imgAll[imgId].imgElem.style.display = 'block';
                  imgAll[imgId].imgFallback.style.display = 'none';
                }
                imgAll[imgId].isImgRemoved = false;
                imgAll[imgId].imgElem.src = e.target.result;
                imgAll[imgId].onHardReset();
              }
            };
            loadFile.readAsDataURL(imgFiles[0]); // read file - once finished - call onload
          }
          // clear file path after it's loaded - to get on change triggered
          // otherwise on same file name it will not trigger the change event
          target.value = '';
        }
      }
    }

    // img move
    function onImgMove(id: string, event: MouseEvent) {
      if (event) {
        imgAll[id].isMoved = true;
        imgAll[id].currentX = event.x;
        imgAll[id].currentY = event.y;
        if (imgAll[id].imgElem) {
          imgAll[id].imgElem.style.left = `${imgAll[id].dx()}px`;
          imgAll[id].imgElem.style.top = `${imgAll[id].dy()}px`;
        }
      }
    }

    // remove event listeners on click release
    function onClickImgRelease(id: string) {
      document.removeEventListener('mousemove', imgAll[id].onImgMoveId);
      document.removeEventListener('mouseup', imgAll[id].onClickImgReleaseId);
      if (imgAll[id].isIntersecting) {
        imgAll[id].prevX = imgAll[id].isMoved ? imgAll[id].dx() : imgAll[id].prevX;
        imgAll[id].prevY = imgAll[id].isMoved ? imgAll[id].dy() : imgAll[id].prevY;
      } else {
        imgAll[id].reset();
        if (imgAll[id].imgElem) {
          imgAll[id].imgElem.style.transform = 'scale(1)';
          imgAll[id].imgElem.style.top = '0px';
          imgAll[id].imgElem.style.left = '0px';
        }
      }
      imgAll[id].isMoved = false;
      isImgClicked.value = false;
    }

    // get click coordinates for img
    function onClickImg(id: string, event: MouseEvent) {
      if (event) {
        if (event.button !== 0) return;
        imgAll[id].startX = event.x;
        imgAll[id].startY = event.y;
        imgAll[id].onImgMoveId = onImgMove.bind(null, id);
        imgAll[id].onClickImgReleaseId = onClickImgRelease.bind(null, id);
        document.addEventListener('mousemove', imgAll[id].onImgMoveId);
        document.addEventListener('mouseup', imgAll[id].onClickImgReleaseId);
        isImgClicked.value = true;
      }
    }

    // remove img
    function removeImg() {
      if (imgAll[imgId].imgElem && imgAll[imgId].imgFallback) {
        imgAll[imgId].isImgRemoved = true;
        imgAll[imgId].reset();
        imgAll[imgId].imgElem.style.display = 'none';
        imgAll[imgId].imgElem.style.top = '0px';
        imgAll[imgId].imgElem.style.left = '0px';
        imgAll[imgId].imgElem.style.transform = 'scale(1)';
        imgAll[imgId].imgFallback.style.display = 'block';
      }
    }

    // get click coordinates for container
    function onClickImgContainer(event: MouseEvent) {
      if (event.button === 2) {
        const element = event.target as HTMLImageElement | HTMLDivElement;
        if (element.children.length === 0) {
          imgId = element.parentElement?.id as string;
        } else {
          imgId = element.id;
        }
        dropDownXImg.value = event.clientX;
        dropDownYImg.value = event.clientY;
        dropDownImg.value = true;
      }
      return false;
    }

    // wheel event - scaling
    function scaleImg(id: string, event: WheelEvent) {
      // minus sign wheel up
      if (imgAll[id].imgElem) {
        if (event.deltaY > 0) {
          imgAll[id].imgElem.style.transform = `scale(${imgAll[id].zoomIn()})`;
        }
        if (event.deltaY < 0) {
          imgAll[id].imgElem.style.transform = `scale(${imgAll[id].zoomOut()})`;
        }
      }
    }

    // entering the img field add scroll event img
    function onMouseEnterImgContainer(id: string) {
      imgAll[id].scaleImgId = scaleImg.bind(null, id);
      imgAll[id].imgContainer.addEventListener('wheel', imgAll[id].scaleImgId);
      window.addEventListener('contextmenu', disableContextMenu);
      hideScrollBar.value = true;
      isImgRemove.value = imgAll[id].isImgRemoved;
    }

    function onMouseLeaveImgContainer(id: string) {
      imgAll[id].imgContainer.removeEventListener('wheel', imgAll[id].scaleImgId);
      window.removeEventListener('contextmenu', disableContextMenu);
      hideScrollBar.value = false;
    }

    // YouTube related functions
    function onClickAddEditYouTube(show: boolean) {
      showYouTubeModal.value = show;
    }

    function closeAddEditModal() {
      showYouTubeModal.value = false;
    }

    // add event listeners to incoming HTML - do it async!
    onMounted(async () => {
      const routeId = Number(route.params.id);
      try {
        await store.dispatch({
          type: 'setIsFetching',
          data: true,
        });
        // get permissions if there is non
        if (!store.getters[AUTH_STORE.GETTERS.PERMISSIONS]) await store.dispatch(AUTH_STORE.ACTIONS.PERMISSIONS);
        // set permissions
        await store.dispatch('presentation/setDeletePermission');
        const response = await store.dispatch({
          type: 'presentation/getPresentations',
          dynamicId: route.params.id ? Number(route.params.id) : null,
        });
        // no pres - if id route id exists - go to /presentation
        if (!response) {
          if (routeId) await router.replace(RoutesEnum.PRESENTATION);
          return;
        }
        // update rout only if there is no dynamic id
        if (!route.params.id) await router.replace(`${RoutesEnum.PRESENTATION}/${store.getters['presentation/activePresentationId']}`);
      } catch (e: any) {
        if (e.message === RoutesEnum.NOT_FOUND) {
          await router.push(RoutesEnum.NOT_FOUND);
          return;
        }
        if (e.message === 'Network Error') {
          await store.dispatch(AUTH_STORE.ACTIONS.LOGOUT);
          await router.push(RoutesEnum.LOGIN);
        }
        console.log('Error on Mount', e.message);
      } finally {
        await store.dispatch({
          type: 'setIsFetching',
          data: false,
        });
      }
    });

    // watchers
    // on change of html file
    watch(presentation, () => {
      // in case the prev or next value is null ignore watcher, onMount and onUnmount are dealing with listeners
      // edge case - no presentation - onMount is short-circuited
      if (!store.getters['presentation/initMounted']) store.dispatch('presentation/setInitMounted');
      imgId = '';
      // remove event listeners from prev img
      const objImgKeys = Object.keys(imgAll);
      if (objImgKeys.length !== 0) {
        objImgKeys.forEach((prop: string) => {
          imgAll[prop].onUnmount();
          imgAll[prop].imgContainer.removeEventListener('mousedown', onClickImgContainer);
          // remove all props from the img obj
          delete imgAll[prop];
        });
      }
      // remove event listeners from prev text obj
      const objTextKeys = Object.keys(textAll);
      if (objTextKeys.length !== 0) {
        objTextKeys.forEach((prop) => {
          textAll[prop].textElem.removeEventListener('mouseenter', onMouseEnterTextContainer);
          textAll[prop].textElem.removeEventListener('mouseleave', onMouseLeaveTextContainer);
          textAll[prop].textElem.removeEventListener('input', clickHandlerTextField);
          textAll[prop].textElem.removeEventListener('mousedown', textAll[prop].rightClickTextFieldId);
          // remove all props from the text obj
          delete textAll[prop];
        });
      }
      if (youTubeContainer.value) {
        youTubeContainer.value
          .onUnmount()
          .removeOnClickButtonListener(onClickAddEditYouTube);
        youTubeContainer.value = null;
      }
      // add event listeners to imgAll obj
      setTimeout(async () => {
        const imgElements = document.querySelectorAll('.presentation-image-container');
        const textElements = document.querySelectorAll('.presentation-text');
        const youtubeElements = document.querySelectorAll('.presentation-yt-container');
        if (imgElements.length) {
          const arr = Array.from(imgElements) as HTMLDivElement[];
          arr.forEach((item: HTMLDivElement) => {
            imgAll[item.id] = new Imig(item, '.presentation-image-fallback-add');
            // event listeners for image
            if (imgAll[item.id].imgContainer && imgAll[item.id].imgFallback && imgAll[item.id].imgElem) {
              // eslint-disable-next-line no-unused-expressions
              imgAll[item.id].onImgClickId = onClickImg.bind(null, item.id);
              imgAll[item.id].imgElem.addEventListener('mousedown', imgAll[item.id].onImgClickId);
              // eslint-disable-next-line no-unused-expressions
              imgAll[item.id].onMouseEnterImgContainerId = onMouseEnterImgContainer.bind(null, item.id);
              imgAll[item.id].imgContainer.addEventListener('mouseenter', imgAll[item.id].onMouseEnterImgContainerId);
              // eslint-disable-next-line no-unused-expressions
              imgAll[item.id].onMouseLeaveImgContainerId = onMouseLeaveImgContainer.bind(null, item.id);
              imgAll[item.id].imgContainer.addEventListener('mouseleave', imgAll[item.id].onMouseLeaveImgContainerId);
              // eslint-disable-next-line no-unused-expressions
              imgAll[item.id].imgContainer.addEventListener('mousedown', onClickImgContainer);
            }
          });
        }
        if (textElements.length) {
          const arr = Array.from(textElements) as HTMLDivElement[];
          arr.forEach((item: HTMLDivElement) => {
            textAll[item.id] = new TextContainer(item);
            textAll[item.id].textElem.addEventListener('mouseenter', onMouseEnterTextContainer);
            textAll[item.id].textElem.addEventListener('mouseleave', onMouseLeaveTextContainer);
            textAll[item.id].textElem.addEventListener('input', clickHandlerTextField);
            textAll[item.id].rightClickTextFieldId = rightClickTextField.bind(null, item.id);
            textAll[item.id].textElem.addEventListener('mousedown', textAll[item.id].rightClickTextFieldId);
          });
        }
        if (youtubeElements.length) {
          // TODO: add yt obj and event listeners
          const arr = Array.from(youtubeElements) as HTMLDivElement[];
          const container = arr[0];
          youTubeContainer.value = new YouTubeVideoContainer(container, '.presentation-yt-fallback-add')
            .init()
            .addOnEnterLeaveListeners()
            .addOnClickButtonListener(onClickAddEditYouTube) as YtContainer;
        }
      }, 0);
    });
    onBeforeRouteLeave(async (to) => {
      const activeId = store.getters['presentation/activeView'];
      // updating dynamic id vue-router runs this hook - check includes to prevent
      if (activeId && !to.path.includes(RoutesEnum.PRESENTATION)) {
        try {
          await updateView();
        } catch (e: any) {
          console.log(e.message);
        } finally {
          await store.dispatch('presentation/resetOnUnmount');
          await store.dispatch({
            type: 'setIsFetching',
            data: false,
          });
        }
      }
      // remove token after page updates
      if (to.path === RoutesEnum.LOGIN) await store.dispatch(AUTH_STORE.ACTIONS.LOGOUT);
    });
    // clear presentation store
    onUnmounted(() => {
      // dropdown
      document.removeEventListener('click', hideCustomDropDown);
      document.removeEventListener('contextmenu', disableContextMenu);
    });

    return {
      presentation,
      addImg,
      hideScrollBar,
      isImgClicked,
      dropDownImg,
      dropDownXImg,
      dropDownYImg,
      dropDownTextField,
      dropDownXTextField,
      dropDownYTextField,
      removeImg,
      isImgRemove,
      onDropDownEnter,
      onDropDownLeave,
      boldHandler,
      italicHandler,
      underlineHandler,
      modalShow,
      noData,
      wysiwyg,
      fontChange,
      pastePlaneText,
      showYouTubeModal,
      closeAddEditModal,
      youTubeContainer,
    };
  },
});
