
import { defineComponent, computed } from 'vue';
// generic btn - generic click event is to be implemented
export default defineComponent({
  props: {
    height: {
      type: Number,
      required: true,
    },
    backgroundColor: {
      type: String,
      default: '#e8175d',
    },
    color: {
      type: String,
      default: 'white',
    },
    text: {
      type: String,
      default: 'Generic Btn',
    },
    marginRight: {
      type: [Number, String],
      default: 0,
    },
    marginLeft: {
      type: [Number, String],
      default: 0,
    },
    fontSize: {
      type: Number,
      default: 12,
    },
    onClick: {
      type: [Function],
      default: null,
    },
  },
  setup(props) {
    // btn style
    const style = computed(() => {
      const height = `${props.height}px`;
      const marginRight = typeof (props.marginRight) === 'string' ? props.marginRight : `${props.marginRight}px`;
      const marginLeft = typeof (props.marginLeft) === 'string' ? props.marginLeft : `${props.marginLeft}px`;
      const fontSize = `${props.fontSize}px`;
      return {
        height,
        backgroundColor: props.backgroundColor,
        color: props.color,
        fontWeight: 'bold',
        marginRight,
        fontSize,
        marginLeft,
      };
    });

    return {
      style,
      clickHandler: props.onClick,
    };
  },
});
