
import { defineComponent } from 'vue';
// generic btn - generic click event is to be implemented
export default defineComponent({
  props: {
    src: {
      type: String,
      required: true,
    },
    onClick: {
      type: [Function],
      default: null,
    },
    bc: {
      type: String,
      default: 'black',
    },
    width: {
      type: [String, Number],
      default: '2rem',
    },
  },
  setup() {
    return {};
  },
});
