
import { computed, defineComponent } from 'vue';
import { useStore } from 'vuex';
import { key } from '@/stores';
import { AUTH_STORE } from '@/stores/modules/auth/auth.constants';
import { onBeforeRouteLeave } from 'vue-router';
import RoutesEnum from '@/routes.enum';
import RoutesModel from '@/models/routes.model';

export default defineComponent({
  setup() {
    const store = useStore(key);
    const isLogin = computed(() => store.getters[AUTH_STORE.GETTERS.TOKEN]);

    onBeforeRouteLeave(async (to) => {
      if (to.path === RoutesEnum.LOGIN) await store.dispatch(AUTH_STORE.ACTIONS.LOGOUT);
    });

    return {
      isLogin,
      RoutesModel,
      RoutesEnum,
    };
  },
});
