import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { AUTH_STORE } from '@/stores/modules/auth/auth.constants';
import { Store } from 'vuex';
import { Router } from 'vue-router';
import RoutesEnum from '@/routes.enum';

export default class BaseService<T> {
  static tokenInterceptor: any;

  static errorInterceptor: any;

  // Define a base url for a REST API
  baseURL = process.env.VUE_APP_DOMAIN;

  resource = '';

  static removeInterceptor() {
    axios.interceptors.request.eject(this.tokenInterceptor);
    axios.interceptors.response.eject(this.errorInterceptor);
  }

  static setTokenInterceptor(token: string) {
    // on request interceptor
    this.tokenInterceptor = axios.interceptors.request.use(
      (config: AxiosRequestConfig) => {
        const conf = config;
        if (config.url !== process.env.VUE_APP_AUTH) {
          conf.headers = {
            Authorization: `Bearer ${token}`,
          };
        }
        return conf;
      },
      (error) => Promise.reject(error),
    );
  }

  static setErrorInterceptor(router: Router, store: Store<unknown>) {
    // Add a response interceptor
    this.errorInterceptor = axios.interceptors.response.use(
      (response: AxiosResponse) => response,
      async (error) => {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        if (error.response) {
          if (error.response.status === 401) {
            await store.dispatch(AUTH_STORE.ACTIONS.LOGOUT);
            await router.push(RoutesEnum.LOGIN);
          } else {
            await store.dispatch({ type: 'pushToErrorStack', error });
          }
        }
        return Promise.reject(error);
      },
    );
  }

  get(): Promise<AxiosResponse<T>> {
    return axios.get<T>(`${this.baseURL}/${this.resource}`);
  }

  post(data: any): Promise<AxiosResponse<T>> {
    return axios.post(`${this.baseURL}/${this.resource}`, data);
  }

  delete(): Promise<AxiosResponse<T>> {
    return axios.delete(`${this.baseURL}/${this.resource}`);
  }

  put(data: any): Promise<AxiosResponse<T>> {
    return axios.put(`${this.baseURL}/${this.resource}`, data);
  }
}
