
import { computed, defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { key } from '@/stores';

import ErrorMsg from '@/components/generic/GenericErrorTextField.vue';
import { AUTH_STORE } from '@/stores/modules/auth/auth.constants';
import RoutesEnum from '@/routes.enum';
import InputText from '../components/generic/GenericTextInput.vue';
import InputPassword from '../components/generic/GenericPasswordInput.vue';

export default defineComponent({
  components: {
    InputText,
    InputPassword,
    ErrorMsg,
  },
  created() {
    console.log(this.$refs);
  },
  setup() {
    // router
    const router = useRouter();
    // modules
    const store = useStore(key);
    const userInvalid = ref(false);
    const passInvalid = ref(false);
    const authFailed = ref(false);
    // const formRefs = ['username', 'password'];
    const username = ref<InstanceType<typeof InputText>>();
    const password = ref<InstanceType<typeof InputPassword>>();
    // computed
    const errorMsg = computed(() => store.getters[AUTH_STORE.GETTERS.ERROR_MSG]);

    // methods
    function validator(input: string) {
      return input === '';
    }

    function resetValidation() {
      // reset error msg on key stroke and blur
      if (authFailed.value) {
        authFailed.value = false;
      }
      if (store.getters[AUTH_STORE.GETTERS.ERROR_MSG]) {
        store.dispatch(AUTH_STORE.ACTIONS.SET_ERROR_MSG, null);
      }
    }

    async function login() {
      await router.replace(RoutesEnum.PRESENTATION);
    }

    function submit() {
      if (username.value) {
        username.value.validate();
      }
      if (password.value) {
        password.value.validate();
      }
      const valid = username.value?.isValid && password.value?.isValid;
      const values = {
        username: username.value?.value, password: password.value?.value, store, router,
      };
      if (valid) {
        // dispatch
        const auth = store.dispatch({ type: AUTH_STORE.ACTIONS.LOGIN, ...values });
        // check auth
        auth
          .then(() => {
            authFailed.value = false;
            login();
          })
          .catch((e) => {
            console.log(e);
            authFailed.value = true;
          });
      }
    }

    return {
      userInvalid,
      passInvalid,
      authFailed,
      validator,
      submit,
      errorMsg,
      resetValidation,
      username,
      password,
    };
  }
  ,
});
