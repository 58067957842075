import { Routes, RoutesDE } from '@/routes.enum';

class RoutesModel {
  login = {
    icon: null,
    path: Routes.LOGIN,
    pathDe: RoutesDE.LOGIN,
    name: 'Anmelden',
    clickable: false,
  };

  order = {
    // eslint-disable-next-line global-require
    icon: require('@/assets/order.svg'),
    path: Routes.ORDER,
    pathDe: RoutesDE.ORDER,
    name: 'Aufträge',
    clickable: true,
  };

  presentation = {
    // eslint-disable-next-line global-require
    icon: require('@/assets/presentation.svg'),
    path: Routes.PRESENTATION,
    pathDe: RoutesDE.PRESENTATION,
    name: 'Präsentation',
    clickable: true,
  };

  notFound = {
    icon: null,
    path: Routes.NOT_FOUND,
    pathDe: RoutesDE.NOT_FOUND,
    name: 'Not Found',
    clickable: false,
  };

  getHeading(path: string | null) {
    if (typeof path !== 'string') return '';
    const res = Object.values(this)
      .find((item) => path.includes(item.path));
    return res?.name || '';
  }

  getClickableItems() {
    return Object.values(this)
      .filter((item) => item.clickable);
  }
}

export default new RoutesModel();
