import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-047d8060"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "loader tw-w-[5rem] tw-h-[5rem]"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([[{fetching:_ctx.fetching}], "tw-fixed tw-w-screen tw-h-screen tw-z-[1000] tw-flex tw-justify-center tw-items-center"])
  }, [
    (_ctx.fetching)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1))
      : _createCommentVNode("", true)
  ], 2))
}