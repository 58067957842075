import { useStore } from 'vuex';
import { key } from '@/stores';
import { submitPUT } from '@/models/presentation.model';
import useThumbnailCreatePresentation from '@/customHooks/use-thumbnail-create-presentation';

function useUpdateViewPresentation() {
  const store = useStore(key);
  const { updateThumbnail } = useThumbnailCreatePresentation();

  async function updateView() {
    try {
      const currentId = store.getters['presentation/activeView'];
      const viewNames = store.getters['presentation/activePresentationViewNames'];
      if (!currentId) return;
      // start spinner
      await store.dispatch({
        type: 'setIsFetching',
        data: true,
      });
      await updateThumbnail();
      const payloadPUT = submitPUT();
      await store.dispatch({
        type: 'presentation/updateView',
        data: payloadPUT,
        view: viewNames[currentId],
        id: currentId,
      });
    } catch (e: any) {
      throw new Error(e.message);
    }
  }

  return {
    updateView,
  };
}

export default useUpdateViewPresentation;
