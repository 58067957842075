import { ActionTree, Store } from 'vuex';
import { AuthActionsTypes, AuthStateTypes } from '@/stores/modules/auth/auth.interface';
import { IRootState } from '@/stores/interfaces';
import { AUTH_STORE } from '@/stores/modules/auth/auth.constants';
import BaseService from '@/services/base.service';
import AuthService from '@/services/auth.service';
import { Router } from 'vue-router';
import UserService from '@/services/user.service';

export const authActions: ActionTree<AuthStateTypes, IRootState> & AuthActionsTypes = {
  async [AUTH_STORE.ACTIONS.TRY_LOGIN]({ commit, dispatch }, { router, store }) {
    const token = localStorage.getItem('token');
    try {
      if (token) {
        commit(AUTH_STORE.MUTATIONS.LOGIN, token);
        BaseService.setTokenInterceptor(token);
        BaseService.setErrorInterceptor(router, store);
      }
    } catch (e:any) {
      console.log(e.message);
    }
  },
  async [AUTH_STORE.ACTIONS.LOGIN]({
    commit,
    dispatch,
  }, {
    username,
    password,
    router,
    store,
  }: { username: string, password: string, router: Router, store: Store<unknown> }) {
    // getting auth token
    try {
      const response = await new AuthService().post({
        email: username,
        password,
      });
      // save local storage
      localStorage.setItem('token', (response as any).data.token);
      commit(AUTH_STORE.MUTATIONS.LOGIN, (response as any).data.token);
      // add interceptors
      BaseService.setTokenInterceptor((response as any).data.token);
      BaseService.setErrorInterceptor(router, store);
      // get permissions
      await dispatch(AUTH_STORE.ACTIONS.PERMISSIONS);
    } catch (e: any) {
      // set error msg
      await dispatch(AUTH_STORE.ACTIONS.SET_ERROR_MSG, 'Nutzername oder Passwort ungültig');
      throw new Error(e.message);
    }
  },
  async [AUTH_STORE.ACTIONS.PERMISSIONS]({ commit }) {
    try {
      const response:any = await new UserService().get();
      commit(AUTH_STORE.MUTATIONS.PERMISSIONS, response.data.data.permissions);
    } catch (e:any) {
      throw new Error(e.message);
    }
  },
  async [AUTH_STORE.ACTIONS.SET_ERROR_MSG]({ commit }, payload: string) {
    commit(AUTH_STORE.MUTATIONS.SET_ERROR_MSG, payload);
  },
  async [AUTH_STORE.ACTIONS.LOGOUT]({ commit }) {
    BaseService.removeInterceptor();
    commit(AUTH_STORE.MUTATIONS.LOGOUT, undefined);
  },
};

export default authActions;
