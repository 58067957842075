import { GetterTree } from 'vuex';
import { OrderGettersTypes, OrderStateTypes } from '@/stores/modules/order/order.interface';
import { IRootState } from '@/stores/interfaces';
import { ORDER_STORE } from '@/stores/modules/order/order.constants';

export const orderGetters: GetterTree<OrderStateTypes, IRootState> & OrderGettersTypes = {
  [ORDER_STORE.GETTERS.ORDERS]: (state: OrderStateTypes) => state.orders,
};

export default orderGetters;
