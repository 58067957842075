
import { computed, defineComponent } from 'vue';
import GenericIcon from '@/components/generic/GenericIcon.vue';
import { useStore } from 'vuex';
import { key } from '@/stores';

export default defineComponent({
  components: { GenericIcon },
  setup() {
    const store = useStore(key);
    const errorStack = computed(() => store.getters.errorStack);

    // clear snackbar
    async function clearSnackbar(index: number) {
      await store.dispatch({ type: 'removeFromErrorStack', id: index });
    }

    return {
      errorStack,
      clearSnackbar,
    };
  },
});
