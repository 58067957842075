import PresentationService from '@/services/presentation.service';
import PrintService from '@/services/print.service';
import ClientService from '@/services/client.service';
import html2canvas from 'html2canvas';
import MediaService from '@/services/media.service';
import RoutesEnum from '@/routes.enum';
import { AUTH_STORE } from '@/stores/modules/auth/auth.constants';

export default {
  namespaced: true,
  namespace: 'presentation',
  state() {
    return {
      html: null,
      modalShow: false,
      layouts: [],
      companyNames: [],
      presentationIds: [],
      activePresentationName: null,
      activePresentationViewIds: [],
      activePresentationViewNames: {},
      activePresentationId: null,
      activeViewId: null,
      changeToView: null,
      saveTemplateName: null,
      clickedTemplateId: null,
      noData: false,
      publicUrl: null,
      notice: null,
      customerName: null,
      initMounted: false,
      clientId: null,
      presentationThumbnails: {},
      deletePermission: true,
      logoMediaId: null,
    };
  },
  mutations: {
    updateThumbnail(state: any, payload: any) {
      const id = payload.data.viewId;
      // only add in on init when thumb id is null
      if (!state.presentationThumbnails[id].id) state.presentationThumbnails[id].id = payload.data.newThumbId;
      state.presentationThumbnails[id].data = payload.data.base64;
    },
    setCompanyNames(state: any, payload: any) {
      state.companyNames = payload.data;
    },
    setInitMounted(state: any) {
      state.initMounted = true;
    },
    reorderViewIds(state: any, payload: any) {
      state.activePresentationViewIds = payload.data;
    },
    setNoData(state: any, payload: any) {
      state.noData = payload.data;
    },

    test(state: any, payload: any) {
      state.html = payload.data.data;
    },

    modalShow(state: any, payload: any) {
      if (payload.changeToId) {
        state.modalShow = payload.data;
        state.changeToView = payload.changeToId;
      } else {
        state.modalShow = payload.data;
      }
    },

    setLayouts(state: any, payload: any) {
      state.layouts = payload.data.data.data;
    },

    setPresentationsIds(state: any, payload: any) {
      if (state.presentationIds.length > 0) state.presentationIds = [];
      payload.data.data.data.forEach((item: any) => {
        state.presentationIds.push(item.id);
      });
      if (!state.presentationIds.length) {
        state.activePresentationId = state.presentationIds[state.presentationIds.length - 1];
      }
    },

    changePresentationId(state: any, payload: any) {
      state.activePresentationId = payload.id;
      state.activeViewId = null;
    },

    setActivePresentation(state: any, payload: any) {
      state.clientId = payload.data.data.client_id;
      state.notice = payload.data.data.notice;
      state.activePresentationName = payload.data.data.name;
      state.customerName = payload.data.data.customer_name;
      state.publicUrl = payload.data.data.url_public;
      state.activePresentationId = payload.data.data.id;
      state.logoMediaId = payload.data.data.media.length === 0 ? null : payload.data.data.media[0].id;
      if (state.activePresentationViewIds.length > 0) state.activePresentationViewIds = [];
      if (Object.keys(state.activePresentationViewNames).length > 0) {
        state.activePresentationViewNames = {};
      }
      if (Object.keys(state.presentationThumbnails).length > 0) state.presentationThumbnails = {};
      payload.data.data.print_data.forEach((view: any, index: number) => {
        // update thumbnail obj
        state.presentationThumbnails[view.id] = {
          data: view.thumbnail.data,
          id: view.thumbnail.id,
        };
        // only declare on init and on new Presentation
        if (index === 0 && !state.activeViewId) state.activeViewId = view.id;
        state.activePresentationViewIds.push(view.id);
        state.activePresentationViewNames[view.id] = view.view;
      });
      // on empty presentation clear viewId and changeToId from previous pres
      if (!payload.data.data.print_data.length) {
        state.html = '<h1 style="font-size: 20px">Bitte Folie Hinzufügen</h1>';
        state.activeViewId = null;
        state.changeToView = null;
      }
    },

    setActiveViewId(state: any, payload: any) {
      state.activeViewId = payload.id;
    },

    resetOnUnmount(state: any) {
      state.html = null;
      state.modalShow = false;
      state.layouts = [];
      state.companyNames = [];
      state.presentationIds = [];
      state.activePresentationName = null;
      state.activePresentationViewIds = [];
      state.activePresentationViewNames = {};
      state.activePresentationId = null;
      state.activeViewId = null;
      state.changeToView = null;
      state.saveTemplateName = null;
      state.clickedTemplateId = null;
      state.publicUrl = null;
      state.initMounted = false;
      state.clientId = null;
      state.notice = null;
      state.customerName = null;
      state.logoMediaId = null;
      state.presentationThumbnails = {};
    },

    setClickedTemplateId(state: any, payload: any) {
      const clickedId = payload.id;
      if (!state.clickedTemplateId && clickedId) state.clickedTemplateId = [];
      if (state.clickedTemplateId && !clickedId) state.clickedTemplateId = null;
      if (clickedId) {
        if (state.clickedTemplateId.includes(clickedId)) {
          const toFilter = [...state.clickedTemplateId];
          state.clickedTemplateId = toFilter.filter((id) => id !== clickedId);
        } else {
          state.clickedTemplateId.push(payload.id);
        }
      }
    },
    setDeletePermission(state:any, payload:any) {
      state.deletePermission = payload.permission;
    },
  },
  actions: {
    updateThumbnail({ commit }: { commit: any }, payload: any) {
      commit({
        type: 'updateThumbnail',
        data: payload.data,
      });
    },
    async getCompanyNames({ commit }: { commit: any }) {
      try {
        const response: any = await new ClientService().get();
        commit({
          type: 'setCompanyNames',
          data: response.data.data.data,
        });
      } catch (e: any) {
        throw new Error(e.message);
      }
    },
    setInitMounted({ commit }: { commit: any }) {
      commit('setInitMounted');
    },
    setNoData({ commit }: { commit: any }, payload: any) {
      commit({
        type: 'setNoData',
        data: payload.data,
      });
    },

    modalShow({ commit }: { commit: any }, payload: any) {
      if (payload.changeToId) {
        commit({
          type: 'modalShow',
          data: payload.data,
          changeToId: payload.changeToId,
        });
      } else {
        commit({
          type: 'modalShow',
          data: payload.data,
        });
      }
    },

    async newPresentation({ dispatch }: { dispatch:any }, payload: any) {
      try {
        const response:any = await new PresentationService()
          .post(payload.data);
        if (payload.rawData.length > 0) {
          const mediaResponse = await new MediaService().post({
            model_id: response.data.data.id,
            model_type: 'Presentation',
            is_thumb: false,
            media: payload.rawData,
          });
        }
        await dispatch({ type: 'getPresentations' });
      } catch (e:any) {
        throw new Error(e.message);
      }
    },

    async duplicatePresentation({ dispatch, commit, state }:{ dispatch:any, commit:any, state:any }, payload:any) {
      try {
        await new PresentationService().id(payload.id).duplicate().post({});
        await dispatch({ type: 'getPresentations' });
        commit({ type: 'setActiveViewId', id: state.activePresentationViewIds[0] });
      } catch (e:any) {
        throw new Error(e.message);
      }
    },

    async getPresentations({
      commit,
      dispatch,
      getters,
    }: { commit: any, dispatch: any, getters: any }, payload: { [key: string]: any }) {
      try {
        const perPage = 100;
        let isMountTriggered = false;
        // check if mount hook calls
        isMountTriggered = Object.keys(payload)
          .includes('dynamicId');
        const response = await new PresentationService().perPage(perPage)
          .get() as any;
        if (response.data.data.last_page !== 1) {
          const numOfPages = response.data.data.last_page;
          let tempPresentationsList = [] as any;
          // eslint-disable-next-line no-plusplus
          for (let page = 2; page <= numOfPages; page++) {
            // eslint-disable-next-line no-await-in-loop
            const responseTemp = await new PresentationService().perPage(perPage).nextPage(page).get() as any;
            tempPresentationsList = [...tempPresentationsList, ...responseTemp.data.data.data];
          }
          tempPresentationsList = [...response.data.data.data, ...tempPresentationsList];
          response.data.data.data = tempPresentationsList;
        }
        commit({
          type: 'setPresentationsIds',
          data: response.data,
        });
        // get last presentation
        let lastId = getters.presentationIds[getters.presentationIds.length - 1];
        if (!lastId) {
          if (getters.noData) {
            await dispatch({ type: 'setNoData', data: false });
            await dispatch('resetOnUnmount');
          }
          return Promise.resolve(false);
        }
        await dispatch({
          type: 'setNoData',
          data: true,
        });
        // check if it's called on mount and dispatch - depending on a case
        if (isMountTriggered && payload.dynamicId) {
          const { dynamicId } = payload;
          if (getters.presentationIds.includes(dynamicId)) {
            lastId = dynamicId;
          } else {
            throw new Error(RoutesEnum.NOT_FOUND);
          }
        }
        // if delete presentation is call reassign lastId;
        if (payload.id) lastId = payload.id;
        await dispatch({
          type: 'getPresentation',
          id: lastId,
        });
      } catch (e: any) {
        throw new Error(e.message);
      }
      return Promise.resolve('all cool');
    },

    async getPresentation({
      commit,
      dispatch,
      state,
    }: { commit: any, dispatch: any, state: any }, payload: any) {
      try {
        const response: any = await new PresentationService().id(payload.id)
          .include('printData,media')
          .get();
        // check if there are slides in presentation
        commit({
          type: 'setActivePresentation',
          data: response.data,
        });
        if (response.data.data.print_data.length) {
          await dispatch({
            type: 'getView',
            id: payload.viewId ? payload.viewId : state.activePresentationViewIds[0],
            updateId: !!payload.viewId,
          });
        } else {
          await dispatch('getLayouts');
          await dispatch({ type: 'modalShow', data: 'add' });
        }
      } catch (e: any) {
        throw new Error(e.message);
      }
    },

    resetActiveId({ commit }: { commit: any }, payload: any) {
      commit({
        type: 'changePresentationId',
        id: payload.id,
      });
    },

    async addView({
      commit,
      getters,
      dispatch,
    }: { commit: any, getters: any, dispatch: any }, payload: any) {
      try {
        const postObj: {[key: string]: any} = {
          preview: false,
          model_type: 'Presentation',
          model_id: getters.activePresentationId,
          store: true,
          data: [],
        };
        // check what is selected - view or saved template
        if (payload.template_ids) {
          postObj.template_ids = payload.template_ids;
          postObj.store_pdf = false;
          postObj.is_template = false;
        }
        if (payload.view !== '') {
          postObj.view = payload.view;
        }
        const response: any = await new PrintService().post(postObj);
        let viewId = -1; // get last viewId;
        if (!response.data?.responses) {
          viewId = response.data.data.id;
        } else {
          const lastView = response.data.responses.pop();
          viewId = lastView.data.id;
        }
        // fetch updated presentation - on the same presentation
        await dispatch({
          type: 'getPresentation',
          id: getters.activePresentationId,
          viewId,
        });
      } catch (e: any) {
        throw new Error(e.message);
      }
    },

    async deleteView({
      getters,
      dispatch,
    }: { getters: any, dispatch: any }) {
      try {
        if (!getters.activeView) throw new Error('id is not a number');
        const viewId = getters.activeView;
        const viewArrIds: number[] = getters.viewIds;
        const index = viewArrIds.indexOf(viewId);
        let changeToId = null as number |null;
        if (index <= viewArrIds.length - 1 && index > 0) {
          changeToId = viewArrIds[index - 1];
        } else if (index === 0 && viewArrIds.length > 1) {
          changeToId = viewArrIds[index + 1];
        }
        // delete
        await dispatch({
          type: 'modalShow',
          data: 'delete',
          changeToId,
        });
      } catch (e: any) {
        console.log(e.message);
      }
    },

    async getView({ commit }: { commit: any }, payload: any) {
      try {
        const response = await new PrintService().id(payload.id)
          .preview()
          .include('noLayout', true)
          .get();
        commit({
          type: 'test',
          data: response,
        });
        if (payload.updateId) {
          commit({
            type: 'setActiveViewId',
            id: payload.id,
          });
        }
      } catch (e: any) {
        throw new Error(e.message);
      }
    },

    async updateView({
      commit,
      dispatch,
      getters,
    }: { commit: any, dispatch: any, getters: any }, payload: any) {
      try {
        await new PrintService()
          .put({
            view: payload.view,
            preview: false,
            store: true,
            model_type: 'Presentation',
            model_id: getters.activePresentationId,
            data: payload.data,
            id: payload.id,
          });
      } catch (e: any) {
        throw new Error(e.message);
      }
    },

    resetOnUnmount({ commit }: { commit: any }) {
      commit('resetOnUnmount');
    },

    async getLayouts({ commit }: { commit: any }) {
      try {
        const response = await new PrintService().views()
          .get();
        commit({
          type: 'setLayouts',
          data: response,
        });
      } catch (e: any) {
        throw new Error(e.message);
      }
    },

    async saveTemplate({ commit }: { commit: any }, payload: any) {
      try {
        const response: any = await new PrintService().post({
          view: payload.view,
          preview: false,
          store: true,
          is_template: true,
          template_name: payload.name,
          data: payload.data,
        });
        const savedTemplateId = response.data.data.id;
        const presentationMain = document.querySelector('.presentation-main') as HTMLElement | null;
        if (presentationMain) {
          const canvas = await html2canvas(presentationMain, {
            onclone: (_, element) => {
              // eslint-disable-next-line no-param-reassign
              element.style.transform = 'scale(0.2)';
            },
          });
          // convert to img bas64
          const data = canvas.toDataURL('image/jpeg', 0.4);
          await new MediaService().post({
            model_id: savedTemplateId,
            model_type: 'printData',
            is_thumb: true,
            media: data,
          });
        } else {
          throw new Error('presentation-main not found');
        }
      } catch (e: any) {
        console.log(e.message);
      } finally {
        commit({
          type: 'modalShow',
          data: false,
        });
      }
    },

    // add deleteTemplate
    async deleteTemplate({
      commit,
      getters,
    }: { commit: any, getters: any, }) {
      try {
        if (getters.clickedTemplateId.length !== 1) throw new Error('template ids array has more than one element');
        const [deleteId] = getters.clickedTemplateId;
        await new PrintService().id(deleteId)
          .delete();
        commit({
          type: 'setClickedTemplateId',
          id: null,
        });
      } catch (e: any) {
        throw new Error(e.message);
      }
    },
    async editPresentation({
      getters,
      dispatch,
    }: { getters: any, dispatch: any }, payload: any) {
      const presentationId = getters.activePresentationId;
      const viewId = getters.activeView;
      const logoId = getters.logoMediaId;
      // eslint-disable-next-line camelcase
      const data: { id: number; name?: string; notice?: string; is_public?: boolean } = { id: presentationId };
      const obj = Object.keys(payload.data); // check if empty sting
      obj.forEach((item) => {
        if (payload.data[item] !== '') {
          data[item as 'name' | 'notice' | 'is_public'] = payload.data[item];
        }
      });
      try {
        const response:any = await new PresentationService().put(data);
        // if logo already exists - update
        if (logoId !== null && payload.rawData.length > 0) {
          await new MediaService().id(logoId).post({ media: payload.rawData });
        }
        // if logo not exists create - create
        if (logoId === null && payload.rawData.length > 0) {
          await new MediaService().post({
            model_id: response.data.data.id,
            model_type: 'Presentation',
            is_thumb: false,
            media: payload.rawData,
          });
        }
        await dispatch({
          type: 'getPresentation',
          id: presentationId,
          viewId,
        });
      } catch (e: any) {
        throw new Error(e);
      }
    },

    async printPDF({
      getters,
    }: { getters: any, rootState: any }) {
      const presentationId = getters.activePresentationId;
      try {
        // TODO: axios what to do -
        //  either create new method with this config or make get method more generic @manu
        const response: any = await new PresentationService().id(presentationId)
          .pdf()
          .getPdf();
        const pdf = new Blob([response.data], { type: 'application/pdf' });
        const pdfLink = window.URL.createObjectURL(pdf);
        window.open(pdfLink);
        // TODO: remove blob on page new page unload - release memory - for now no solution
      } catch (e: any) {
        throw new Error(e.message);
      }
    },

    setClickedTemplateId({ commit }: { commit: any }, payload: any) {
      commit({
        type: 'setClickedTemplateId',
        id: payload.id,
      });
    },
    reorderViewIds({ commit }: { commit: any }, payload: any) {
      commit({
        type: 'reorderViewIds',
        data: payload.data,
      });
    },
    async sortPresentations({ getters }: { getters: any }, payload: any) {
      const { id } = payload;
      const { sortId } = payload;
      try {
        await new PrintService()
          .put({
            preview: false,
            sort: sortId,
            model_type: 'Presentation',
            model_id: getters.activePresentationId,
            id,
          });
      } catch (e: any) {
        throw new Error(e.message);
      }
    },
    async deletePresentation({ getters, dispatch, commit }:{getters:any, dispatch:any, commit:any}) {
      try {
        const presId = getters.activePresentationId;
        const { presentationIds } = getters;
        let newPresId = null as number | null;
        const index = presentationIds.indexOf(presId);
        await new PresentationService().id(presId).delete();
        const presIdsLen = presentationIds.length;
        // where to go
        if (presIdsLen > 1) {
          if (index + 1 === presIdsLen) newPresId = presentationIds[index - 1];
          if (index === 0) newPresId = presentationIds[index + 1];
          if (index > 0 && index < presIdsLen - 1) newPresId = presentationIds[index + 1];
        }
        commit({ type: 'setActiveViewId', id: null });
        await dispatch({ type: 'getPresentations', id: newPresId });

        if (presIdsLen === 1) return Promise.resolve(false);
        return Promise.resolve(presId);
      } catch (e:any) {
        throw new Error(e.message);
      }
    },
    setDeletePermission({ commit, rootGetters }:{commit:any, rootGetters:any}) {
      const permissionsRaw = [...rootGetters[AUTH_STORE.GETTERS.PERMISSIONS]];
      const permissions = permissionsRaw.map((item) => item.name).filter((permission) => {
        if (permission.includes('delete')) {
          return true;
        }
        return false;
      });
      commit({ type: 'setDeletePermission', permission: permissions.length > 0 });
    },
  },
  getters: {
    test(state: any) {
      return state.html;
    },
    modalShow(state: any) {
      return state.modalShow;
    },
    layouts(state: any) {
      return state.layouts;
    },
    activePresentationName(state: any) {
      return state.activePresentationName;
    },
    viewIds(state: any) {
      return state.activePresentationViewIds;
    },
    activeView(state: any) {
      return state.activeViewId;
    },
    activePresentationId(state: any) {
      return state.activePresentationId;
    },
    activePresentationViewNames(state: any) {
      return state.activePresentationViewNames;
    },
    presentationIds(state: any) {
      return state.presentationIds;
    },
    changeToView(state: any) {
      return state.changeToView;
    },
    clickedTemplateId(state: any) {
      return state.clickedTemplateId;
    },
    noData(state: any) {
      return state.noData;
    },
    publicUrl(state: any) {
      return state.publicUrl;
    },
    initMounted(state: any) {
      return state.initMounted;
    },
    companyNames(state: any) {
      return state.companyNames;
    },
    clientId(state: any) {
      return state.clientId;
    },
    presentationThumbnails(state: any) {
      return state.presentationThumbnails;
    },
    notice(state: any) {
      return state.notice;
    },
    logoMediaId(state:any) {
      return state.logoMediaId;
    },
    customerName(state: any) {
      return state.customerName;
    },
    deletePermission(state:any) {
      return state.deletePermission;
    },
  },
};
