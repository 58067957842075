import axios from 'axios';
import BaseService from '@/services/base.service';

export default class PresentationService<T> extends BaseService<T> {
  // Set the resource route of the model
  resource = 'presentations';

  id(id: number) {
    this.resource += `/${id}`;
    return this;
  }

  include(toInclude: string) {
    this.resource += `?include=${toInclude}`;
    return this;
  }

  duplicate() {
    this.resource += '/duplicate';
    return this;
  }

  search(term: string) {
    this.resource += `?search=${term}&per_page=499`;
    return this;
  }

  pdf() {
    this.resource += '/pdf';
    return this;
  }

  getPdf() {
    return axios.get<T>(`${this.baseURL}/${this.resource}`, {
      responseType: 'arraybuffer',
    });
  }

  perPage(numItems: number) {
    this.resource += `?per_page=${numItems}`;
    return this;
  }

  nextPage(page:number) {
    this.resource += `&page=${page}`;
    return this;
  }
}
