import { MutationTree } from 'vuex';
import {
  AuthMutationsTypes,
  AuthStateTypes,
} from '@/stores/modules/auth/auth.interface';
import { AUTH_STORE } from '@/stores/modules/auth/auth.constants';

export const authMutations: MutationTree<AuthStateTypes> & AuthMutationsTypes = {
  [AUTH_STORE.MUTATIONS.LOGIN](
    state: AuthStateTypes,
    payload: string,
  ) {
    state.token = payload;
  },
  [AUTH_STORE.MUTATIONS.LOGOUT](
    state: AuthStateTypes,
  ) {
    localStorage.removeItem('token');
    state.token = undefined;
    state.permissions = undefined;
  },
  [AUTH_STORE.MUTATIONS.SET_ERROR_MSG](
    state: AuthStateTypes,
    payload: string,
  ) {
    state.errorMsg = payload;
  },
  [AUTH_STORE.MUTATIONS.PERMISSIONS](
    state: AuthStateTypes,
    payload: any[],
  ) {
    console.log(payload);
    state.permissions = payload;
  },
};

export default authMutations;
